import { Button, Col } from 'antd';
import { Formik } from 'formik';
import { Input, InputNumber, Select } from '@jbuschke/formik-antd';
import React, { useState } from 'react';
import MainTable from './components/MainTable';
import styles from './index.module.scss';

const { Option } = Select;

function OrderList() {
  const [param, setParam] = useState({});

  return (
    <div className={styles.page}>
      <Formik
        initialValues={param}
        onSubmit={(val) => setParam(val)}
        render={(props) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Col>
              <Input
                name='id'
                className={styles.inputStyle}
                placeholder='请输入訂單ID'
              />
              <InputNumber
                name='money'
                min={0}
                precision={2}
                className={styles.inputStyle}
                placeholder='请输入禮物總計'
              />
              <Select
                name='status'
                className={styles.inputStyle}
                placeholder='請選擇訂單狀態'
              >
                <Option value={1}>已支付</Option>
                <Option value={0}>未支付</Option>
              </Select>
              <Button
                type='primary'
                block
                className={styles.subBtnStyle}
                onClick={props.handleSubmit}
              >
                查询
              </Button>
            </Col>
          </div>
        )}
      />
      <div className={styles.mainTable}>
        <MainTable param={param} />
      </div>
    </div>
  );
}

export default OrderList;
