import React from 'react';
import { Spin } from 'antd';
import styles from './index.module.scss';

function Loading() {
  return (
    <div className={styles.container}><Spin tip="Loading..." /></div>
  );
}

export default Loading;
