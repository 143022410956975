import React from 'react';
import styles from './BasicInfo.module.scss';

const BasicInfo = (props) => {
  const format = (value) => {
    if (!value) {
      return null;
    }
    const timeData = new Date(value);
    if (!(timeData instanceof Date) || isNaN(timeData)) return;
    const pad = '00';
    const yy = timeData.getFullYear().toString();
    const mm = (timeData.getMonth() + 1).toString();
    const dd = timeData.getDate().toString();
    return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
  };
  return (
    <div className={styles.basicInfoContainer}>
      <div className={styles.gapBetweenLine}></div>
      <div className={styles.selfIntroduction}>
        <p className={styles.titleIntroduction}>基本信息</p>
        <div className={styles.padBetweenLine}></div>
        <div className={styles.infoShow}>
          <div style={{ flex: 1 }}>
            <p>中文姓名: {props.name}</p>
            <p>英文姓名: {props.englishName}</p>
            <p>籍贯: {props.originPlace}</p>
            <p>身份证号码: {props.idCardNo}</p>
          </div>
          <div style={{ flex: 1 }}>
            <p>国籍: {props.country}</p>
            <p>民族: {props.nation}</p>
            <p>出生年月: {format(props.bornAt)}</p>
            <p>手机号码: {props.phone}</p>
          </div>
          <div style={{ flex: 1 }}>
            <p>性别: {props.gender ? '女' : '男'}</p>
            <p>婚否: {props.isMarried ? '已婚' : '未婚'}</p>
            <p>现居住地: {props.currentAddress}</p>
            <p>微信号码: {props.wechat}</p>

          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
