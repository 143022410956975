import useReactRouter from 'atv-use-react-router';
import { ResponseError } from 'atv-make-request-factory';
import useFetch from 'atv-use-fetch';
import { useEffect, useState, useRef } from 'react';
import useAppContext from '@/useAppContext';
import { message } from 'antd';

export function useIdOrNew(api, mapResData = v => v, initialData = {}) {
  const { match, history } = useReactRouter();
  const [state, setState] = useState({ data: initialData, loading: true });
  const { id } = match.params;
  const isNew = id === 'new';
  useEffect(() => {
    const { id } = match.params;
    if (isNew) {
      setState({ ...state, loading: false });
      return;
    }
    const load = async () => {
      const res = await api({ params: { id } });
      setState({ data: mapResData(res), loading: false });
    };
    load();
  }, [id]); // eslint-disable-line
  return { state, history, id, isNew };
}

export function useTableHelper(listApi, callDeleteApi, query = { pageSize: 10 }, initailPageNo = 1) {
  const [page, setPage] = useState(initailPageNo);
  const { state, callApi } = useFetch(listApi, { data: { data: [], count: 0 }, loading: true });
  const load = (pageNo) => callApi({ query: { ...query, pageNo } });

  useEffect(() => { load(page); }, []); // eslint-disable-line

  const handlePaginate = pageNo => {
    return load(pageNo).then(() => setPage(pageNo));
  };

  const handleDelete = record => {
    (async () => {
      await callDeleteApi(record);
      return handlePaginate(page);
    })();
  };

  return { page, state, handlePaginate, handleDelete };
}

export function useGenericErrorHandler(cb = () => {}) {
  const [genericError, setGenericError] = useState(null);
  useEffect(() => {
    if (!genericError) {
      message.destroy();
      return;
    }
    if (genericError instanceof ResponseError) {
      message.error(genericError.body.message, 3, () => cb(genericError));
    } else {
      message.error('未知错误', 3, () => cb(genericError));
    }
  }, [genericError]); // eslint-disable-line
  return setGenericError;
}

export function useEnsureGameArea() {
  const { history } = useReactRouter();
  const { gameAreaId } = useAppContext();
  if (!gameAreaId) {
    history.push('/gameAreaMask');
  }
  return gameAreaId;
}

/**
 * @param {Function} effect
 * @param {Array<any>} dependencies
 */
export function useUpdateEffect(effect, dependencies = []) {
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
    // eslint-disable-next-line
  }, dependencies);
}
