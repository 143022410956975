import React, { useState } from 'react';
import { Modal } from 'antd';
import styles from './ChoosePlayer.module.scss';
import { bindMentor } from '@/utils/api';

function ChoosePlayer({ playerLists, visible, handleCancel, handleOk, id, handleChoosePlayer, handleCancelChoosePlayer }) {
  const [mentorId, setMentorId] = useState([]);

  const handleChoose = async (item) => {
    // console.log(item, '选中的那个图片');
    try {
      await bindMentor({
        params: {
          id: item.id,
        },
        body: {
          mentorId: 1, // 0 解绑, 导师id 绑定
        },
      });
      handleChoosePlayer(item);
      setMentorId([...mentorId, item.id]);
      // await Toast.success('提交成功!', 1);
    } catch (err) {
      // Toast.success('提交失败!', 1);
    }
  };
  const handleCancelChoose = async (item) => {
    // if (item.MentorId === id) {
    //   try {
    //     await bindMentor({
    //       params: {
    //         id: item.id,
    //       },
    //       body: {
    //         mentorId: 0, // 0 解绑, 导师id 绑定
    //       },
    //     });
    //     handleCancelChoosePlayer(item);
    //     // await Toast.success('提交成功!', 1);
    //   } catch (err) {
    //     // Toast.success('提交失败!', 1);
    //   }
    // }
    try {
      await bindMentor({
        params: {
          id: item.id,
        },
        body: {
          mentorId: 0, // 0 解绑, 导师id 绑定
        },
      });
      handleCancelChoosePlayer(item);
    } catch (err) {
      // Toast.success('提交失败!', 1);
    }
  };

  function handleShowModal(item) {
    if (item.MentorId === 0) {
      if (mentorId.indexOf(item.id) === -1) {
        return (
          <div onClick={() => handleCancelChoose(item)} className={styles.none}></div>
        );
      } else {
        return (
          <div onClick={() => handleCancelChoose(item)} className={styles.modalFixed}></div>
        );
      }
    } else {
      return (
        <div onClick={() => handleCancelChoose(item)} className={styles.modalFixed}></div>
      );
    }
  }

  return (
    <div>
      <Modal
        title="添加选手"
        cancelText='取消'
        okText='确定'
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className={styles.playerListWarp}>
          {
            playerLists.length > 0 ? playerLists.map((item, index) => {
              return (
                <div key={index} className={styles.listRelative}>
                  <div onClick={() => handleChoose(item)} className={styles.listItem}>
                    <div><img src={item.avatarUrl} className={styles.img} alt="" /></div>
                    <div className={styles.name}>{item.name}</div>
                  </div>
                  {/* 选手已被选则显示白色蒙版 */}
                  {/* <div onClick={() => handleCancelChoose(item)} className={((mentorId.indexOf(item.id) === -1) || (item.MentorId === 0)) ? styles.none : styles.modalFixed}></div> */}
                  {/* <div onClick={() => handleCancelChoose(item)} className={((item.MentorId === 0)||(mentorId.indexOf(item.id) === -1)) ? console.log(mentorId.indexOf(item.id), item.MentorId, '不显示蒙版') : console.log(mentorId.indexOf(item.id), item.MentorId, '显示蒙版')}></div> */}
                  {handleShowModal(item)}
                </div>
              );
            }) : ''
          }
        </div>
      </Modal>
    </div>
  );
}

export default ChoosePlayer;
