/**
 * @author zhaojianhao
 * @description 视频播放组件
 */
import React, { useLayoutEffect } from 'react';
import useJsCss from 'atv-use-js-css';
import styles from './index.module.scss';

const playerJS = '//g.alicdn.com/de/prismplayer/2.8.2/aliplayer-min.js';
const playerCSS = '//g.alicdn.com/de/prismplayer/2.8.2/skins/default/aliplayer-min.css';
const sources = [playerJS, playerCSS];

const VideoPlayer = ({ videoUrl }) => {
  const [loadingSrc] = useJsCss(sources);
  useLayoutEffect(() => {
    if (!window.Aliplayer) return;
    const player = new window.Aliplayer({
      id: 'player-con',
      source: videoUrl || '',
      width: '100%',
      height: '100%',
      autoplay: false,
      isLive: false,
      rePlay: false,
      playsinline: true,
      preload: true,
      controlBarVisibility: 'hover',
      useH5Prism: true,
    });
    return () => {
      player.dispose();
    };
  }, [loadingSrc, videoUrl]);

  return (
    <div className={styles.toBeCenter}>
      <div className={styles.videoPlayer}>
        <div id="player-con" ></div>
      </div>
    </div>
  );
};

export default VideoPlayer;
