import 'braft-editor/dist/index.css';
import React, { useState } from 'react';
import BraftEditor from 'braft-editor';
import useFetchOnce from 'atv-use-fetch-once';
import { ContentUtils } from 'braft-utils';
import { createFileKey, UploadToOSS } from '@/utils/AliOss';
import { getManageUploadToken } from '@/utils/api';
import PropTypes from 'prop-types';
import { message } from 'antd';
import styles from './index.module.scss';

function H5Editor({ defaultValue, onChange }) {
  const { data } = useFetchOnce(getManageUploadToken, {});
  const [editorState, setEditorState] = useState(BraftEditor.createEditorState(defaultValue));

  const uploadImg = (e) => {
    console.log(e)
    const file = e.target.files[0];
    const fileKey = createFileKey(file);
    if (!data || !file) return message.error('圖片選擇失敗');
    UploadToOSS(data, file, fileKey)
      .then((fileUrl) => {
        insertImgBraft(fileUrl);
      })
  };

  const controls = [
    'undo', 'redo', 'separator',
    'font-size', 'line-height', 'letter-spacing', 'separator',
    'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
    'superscript', 'subscript', 'remove-styles', 'emoji', 'separator', 'text-indent', 'text-align', 'separator',
    'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
    'link', 'separator', 'hr', 'separator',
    'clear',
  ];

  const extendControls = [
    'separator',
    {
      key: 'imgUpload',
      type: 'component',
      component: (
        <div className={styles.file}>图片
          <input type="file" accept="image/*" onChange={(e) => uploadImg(e)} />
        </div>
      ),
    },
  ];

  const insertImgBraft = imgUrl => {
    const newState = ContentUtils.insertMedias(editorState, [{
      type: 'IMAGE',
      url: imgUrl,
    }]);
    setEditorState(newState);
  };

  const handleChange = editorState => {
    setEditorState(editorState);
    if (onChange) {
      if (editorState.toHTML() === '<p></p>') {
        onChange('');
      } else {
        onChange(editorState.toHTML());
      }
    }
  };

  const blockExportFn = (contentState, block) => {
    const previousBlock = contentState.getBlockBefore(block.key);
    if (block.type === 'unstyled' && previousBlock && previousBlock.getType() === 'atomic') {
      return {
        start: '',
        end: '',
      };
    }
  };

  return (
    <BraftEditor style={{ border: '1px solid #d9d9d9', flex: 1 }}
      controls={controls}
      extendControls={extendControls}
      converts={{ blockExportFn }}
      value={editorState}
      onChange={handleChange}
    />
  );
}

H5Editor.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default H5Editor;
