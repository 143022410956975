import OSS from 'ali-oss';
import { random } from 'lodash';
import { ALI_OSS_PREFIX } from './constants';

export const UploadToOSS = async (self, file, name) => {
  const { cname, baseUrl, ...OSSTOKEN } = self
  return new Promise((resolve, reject) => {
    new OSS(OSSTOKEN)
      .multipartUpload(`${ALI_OSS_PREFIX}/${name}`, file)
      .then(data => {
        const { res = {}, name } = data || {};
        if (res.status === 200) {
          resolve(`${baseUrl}/${name}`);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const createFileKey = file => {
  if (file) {
    const { type = '', name = '' } = file || {};
    const typeSuffix = type.toString().split('/')[1];
    const nameSuffix = name
      .toString()
      .split('.')
      .reverse()[0];
    const suffix = `.${typeSuffix || nameSuffix}`;
    const prefix = String(Date.now()) + random(1000000, 9999999);
    return `${prefix}${suffix}`;
  }
  return '';
};
