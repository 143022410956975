import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';

function FormHeader({ names, onClick }) {
  return (
    <div className={styles.breadcrumbWrap}>
      <Breadcrumb className={styles.breadcrumb}>
        {
          names.map((v, i) => (
            <Breadcrumb.Item key={i} className={styles.breadcrumbText} onClick={() => onClick && onClick(i)}>
              {v}
            </Breadcrumb.Item>
          ))
        }
      </Breadcrumb>
    </div>
  );
}

FormHeader.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
};

export default FormHeader;
