import React from 'react';
import { Button, message } from 'antd';
import { Input } from '@jbuschke/formik-antd';
import { Formik } from 'formik';
import FormHeader from '@/components/FormHeader';
import ErrorMessage from '@/components/FormErrorMessage';
import useFetch, { INITIAL_STATE, ON_NEW_DATA } from 'atv-use-fetch';
import { addJudge, getJudge, updateJudge } from '@/utils/api';
import { useIdOrNew, useGenericErrorHandler, useEnsureGameArea } from '@/utils/hooks';
import Loading from '@/components/Loading';
import H5Editor from '@/components/H5Editor';
import AliossUploadImage from '@/components/AliossUploadImage';

import styles from './index.module.scss';

const { TextArea } = Input;
const errorMessages = {
  name: '请输入姓名',
  title: '请输入评委头衔',
  description: '请输入简介',
  introduction: '请输入个人介绍',
  avatarUrl: '请上传评委头像',
};

const getErrorMessage = (value) => {
  return Object.keys(errorMessages).reduce((result, name) => {
    if (!value[name]) {
      result[name] = errorMessages[name];
    }
    return result;
  }, {});
};
const changeAvatarUrl = (url, props) => {
  const { setFieldValue } = props;
  setFieldValue('avatarUrl', url);
};
const changeIntroduction = (introduction, props) => {
  const { setFieldValue } = props;
  setFieldValue('introduction', introduction);
};
function Index() {
  const gameAreaId = useEnsureGameArea();
  const mapResData = res => res;
  const handleError = useGenericErrorHandler();
  const initialValues = {
    name: '',
    title: '',
    description: '',
    avatarUrl: null,
    introduction: '',
  };
  const { state, history, id, isNew } = useIdOrNew(getJudge, mapResData, initialValues);
  const { state: mutateState, callApi } = useFetch(
    data => isNew ? addJudge(data) : updateJudge({ params: { id }, ...data }),
    INITIAL_STATE,
    ON_NEW_DATA,
    () => {
      message.success(isNew ? '添加成功' : '修改成功');
      goList();
    },
    err => handleError(err),
  );
  if (state.loading) {
    return <Loading />;
  }
  const onSumbit = values => {
    const { name, title, description, avatarUrl, introduction } = values;
    const requestObject = {
      name,
      title,
      description,
      avatarUrl,
      introduction,
      gameAreaId,
      status: 1,
      bornAt: new Date(),
    };
    callApi({ body: requestObject });
  };
  const goList = () => {
    history.push({ pathname: '/judges' });
  };
  const formHeaderClick = (i) => {
    i === 0 && goList();
  };
  return (
    <div className={styles.container}>
      <FormHeader names={['评委', '评委详情']} onClick={formHeaderClick} />
      <div className={styles.fullScreen}>
        <Formik
          initialValues={state.data}
          onSubmit={values => onSumbit(values)}
          validate={values => getErrorMessage(values)}
          render={props => (
            <div className={styles.globalInterval}>
              <div>
                <div className={styles.toBeCenter}>
                  <div className={styles.title}>评委姓名：</div>
                  <div>
                    <Input name="name" size="default" placeholder="请输入姓名" />
                    <ErrorMessage name="name" />
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.description}>
                  <div className={styles.toBeHorizontal}>
                    <div className={styles.title}>评委头像：</div>
                    <div className={styles.uploadImg}>
                      <div className={styles.imgContent}>
                        <AliossUploadImage
                          defaultValue={state.data.avatarUrl}
                          onChange={(value) => {
                            changeAvatarUrl(value, props);
                          }}
                        />
                      </div>
                      <p className={styles.imgFormat}>请上传一张评委头像，建议尺寸320 * 320像素，格式png、jpg</p>
                      <ErrorMessage name="avatarUrl" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.toBeCenter}>
                  <div className={styles.title}>评委头衔：</div>
                  <div>
                    <Input name="title" size="default" placeholder="请输入评委头衔" />
                    <ErrorMessage name="title" />
                  </div>

                </div>
              </div>
              <div>
                <div className={styles.description}>
                  <div className={styles.toBeHorizontal}>
                    <div className={styles.title}>评委简介：</div>
                    <div>
                      <TextArea rows={3} name="description" size="default" placeholder="请输入简介" />
                      <ErrorMessage name="description" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.description}>
                  <div className={styles.introduction}>
                    <div>
                      <div className={styles.title}>详细介绍：</div>
                    </div>
                    <div>
                      <H5Editor
                        defaultValue={state.data.introduction}
                        onChange={(value) => {
                          changeIntroduction(value, props);
                        }}
                      />
                      <ErrorMessage name="introduction" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <Button
                  onClick={props.handleSubmit}
                  loading={mutateState.loading}
                  className={styles.submitBtn}
                >
                  提交
                </Button>
                <Button
                  className={styles.backBtn}
                  onClick={goList}
                >
                  返回列表
                </Button>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default Index;
