import { Layout, Icon, Menu } from 'antd';
import React, { useMemo } from 'react';
import useReactRouter from 'atv-use-react-router';
import pathToRegexp from 'path-to-regexp';
import { getAuth } from '@/utils/app';

import styles from './Aside.module.scss';

const Aside = ({ routes }) => {
  const { history, location } = useReactRouter();
  const { pathname } = location;
  const authValue = getAuth();

  const routeMatchers = useMemo(() => {
    const result = [];
    collectRouteMatchers(result, routes);
    return result;
  }, [routes]);

  const handleClick = e => {
    history.push(e.key);
  };

  const getSelectedKeys = () => {
    const keys = [];
    for (const matcher of routeMatchers) {
      if (matcher.re.test(pathname)) {
        keys.push(matcher.path);
      }
    }
    return keys;
  };

  return (
    <Layout.Sider width={256} className={styles.aside}>
      <Menu
        onClick={handleClick}
        selectedKeys={getSelectedKeys()}
        mode="inline"
      >
        {routes.map(route => {
          if (authValue.gameAreaId !== 0) {
            if (route.path === '/managers') { return null; }
          }
          return route.routes
            ? <Menu.SubMenu
              key={route.path}
              title={
                <span>
                  <MenuIcon icon={route.icon} />
                  <span>{route.name}</span>
                </span>
              }
            >
              {route.routes.map((subRoute) => <MenuItem key={subRoute.path} route={subRoute} />)}
            </Menu.SubMenu>
            : <MenuItem key={route.path} route={route} />;
        }
        )}
      </Menu>
    </Layout.Sider>
  );
};

function MenuItem({ route, ...props }) {
  if (!route.name) return <></>;
  if (route.icon) {
    return (
      <Menu.Item {...props}>
        <MenuIcon icon={route.icon} />
        {route.name}
      </Menu.Item>
    );
  }
  return <Menu.Item key={route.path}>{route.name}</Menu.Item>;
}

function MenuIcon({ icon }) {
  if (!icon) return <></>;
  if (typeof icon === 'string') {
    return <Icon type={icon} />;
  } else if (typeof icon === 'function') {
    return icon();
  }
  return icon;
}

function collectRouteMatchers(result, routes) {
  for (const route of routes) {
    if (!route.name) continue;
    if (route.path === '/') {
      result.push({ path: route.path, re: /^\/$/ });
      continue;
    }
    result.push({ path: route.path, re: pathToRegexp(route.path, [], { end: false }) });
    if (route.routes) {
      collectRouteMatchers(result, route.routes);
    }
  }
}

export default Aside;
