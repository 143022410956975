import makeRequestFactory from 'atv-make-request-factory';
import { withJWTHeader, postFetch } from '@/utils/app';
import { API_PREFIX } from './constants';

const makeRequest = makeRequestFactory({
  debug: process.env.REACT_APP_MR_DEBUG,
  prefix: API_PREFIX,
  preFetch: options => withJWTHeader(options),
  postFetch,
});

export default makeRequest;
