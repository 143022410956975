import React, { useEffect, useState } from 'react';
import { useTableHelper, useEnsureGameArea, useGenericErrorHandler, useUpdateEffect } from '@/utils/hooks';
import { listSponser, updateSponser, changeSponserLevel } from '@/utils/api';
import ActionsEditDelete from '@/components/ActionsEditDelete';
import useReactRouter from 'atv-use-react-router';
import useFetch from 'atv-use-fetch';
import Thumbnails from '@/components/Thumbnails';
import LinkInput from '@/components/LinkInput';
import { Table, message, Modal } from 'antd';
import OrderLevel from './OrderLevel';
import _ from 'lodash';

const maxSponsorsNum = 12;

const { confirm } = Modal;

function DeleteModal(handleDelete, record) {
  confirm({
    title: '确认删除此赞助商？',
    okText: '确定',
    okType: 'danger',
    cancelText: '取消',
    icon: <i></i>,
    onOk() {
      record && handleDelete(record);
      message.success('删除成功');
    },
    onCancel() {
    },
  });
}

export default function MainTable({ setIsFull, setNewLevel }) {
  const gameAreaId = useEnsureGameArea();
  const handleError = useGenericErrorHandler();
  const callDeleteApi = record => updateSponser({ params: { id: record.id }, body: { status: 2 } });
  const [isOneData, setIsOneData] = useState(false);
  const q = JSON.stringify({ status: 1, gameAreaId });
  const { state, page, handlePaginate, handleDelete } = useTableHelper(listSponser, callDeleteApi, { pageSize: maxSponsorsNum, q });
  const { state: changeLevelState, callApi } = useFetch(changeSponserLevel, {}, () => {},
    () => { handlePaginate(1); message.success('修改成功'); },
    err => handleError(err),);
  const { history } = useReactRouter();
  const sortData = _.sortBy(state.data.data, 'level');

  useEffect(() => {
    if (sortData.length === 1) {
      setIsOneData(true);
    }
  }, [sortData]);

  const levels = [];
  if (sortData) {
    let isFindNewLevel = false;
    sortData.forEach(
      (e, index) => {
        levels.push(e.level);
        if (!isFindNewLevel) {
          if (e.level !== index + 1) {
            setNewLevel(index + 1);
            isFindNewLevel = true;
          } else {
            setNewLevel(levels[levels.length - 1] + 1);
          }
        }
      });
  }

  if (sortData && sortData.length >= maxSponsorsNum) {
    setIsFull(true);
  } else {
    setIsFull(false);
  }

  function MarkFirstLast(data) {
    if (data.length > 1) {
      data[0].first = true;
      data[data.length - 1].last = true;
      return data;
    } else {
      return data;
    }
  }

  function setColumns(columns) {
    if (isOneData) {
      columns.splice(4, 1);
      return columns;
    } else {
      return columns;
    }
  }

  useUpdateEffect(() => {
    handlePaginate(1);
  }, [gameAreaId]); // eslint-disable-line

  const handleTableChange = pagination => {
    handlePaginate(pagination.current);
  };

  const handleEdit = record => {
    history.push('/sponsors/' + record.id, levels);
  };

  const columns = [
    {
      title: '序号',
      width: 60,
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: 'logo',
      width: 100,
      align: 'center',
      dataIndex: 'logoUrl',
      render: v => <Thumbnails images={[v]} center />,
    },
    {
      title: '标题',
      width: 200,
      align: 'center',
      dataIndex: 'title',
    },
    {
      title: '链接',
      width: 100,
      align: 'center',
      dataIndex: 'url',
      render: v => <LinkInput url={v} />,
    },
    {
      title: '排序',
      width: 100,
      align: 'center',
      render: (text, record) =>
        <OrderLevel
          record = {record}
          onUp={() => {
            const id = record.id;
            const changeEle = sortData[levels.indexOf(record.level) - 1];
            if (changeEle) {
              callApi({ body: { gameAreaId, id, changeId: changeEle.id } });
            }
          }}
          onDown={() => {
            const id = record.id;
            const changeEle = sortData[levels.indexOf(record.level) + 1];
            if (changeEle) {
              callApi({ body: { gameAreaId, id, changeId: changeEle.id } });
            }
          } } />,
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      render: (text, record) =>
        <ActionsEditDelete
          onEditBtnClick={() => handleEdit(record)}
          onDeleteBtnclick={() => DeleteModal(handleDelete, record)} />,
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={MarkFirstLast(sortData)}
      pagination={{ current: page, pageSize: maxSponsorsNum, total: state.data.count }}
      onChange={handleTableChange}
      loading={state.loading || changeLevelState.loading}
      columns={setColumns(columns)}
    />
  );
}
