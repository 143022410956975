import React from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { listGiftStats } from '@/utils/api';
import Thumbnails from '@/components/Thumbnails';
import { useEnsureGameArea, useTableHelper, useUpdateEffect } from '@/utils/hooks';
import styles from '../index.module.scss';

const PAGE_SIZE = 8;

function MainTable({ playerId }) {
  const gameAreaId = useEnsureGameArea();
  const param = { gameAreaId };
  if (playerId) param.playerId = playerId;
  const q = JSON.stringify(param);
  const { state, page, handlePaginate } = useTableHelper(listGiftStats, () => {}, { pageSize: PAGE_SIZE, q });

  useUpdateEffect(() => {
    handlePaginate(1);
  }, [playerId, gameAreaId]); // eslint-disable-line

  const handleTableChange = pagination => {
    handlePaginate(pagination.current);
  };

  const renderInfo = (item = {}) => (
    <div className={styles.infoBox}>
      <Thumbnails images={[item.avatarUrl]} />
      <div className={styles.infos}>
        <p>ID：{item.id || '-'}</p>
        <p>名称：{item.name || '-'}</p>
      </div>
    </div>
  );

  const columns = [
    {
      title: 'ID',
      width: 60,
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: '投票用户',
      width: 200,
      align: 'center',
      dataIndex: 'User',
      render: renderInfo,
    },
    {
      title: '参赛选手',
      width: 200,
      align: 'center',
      dataIndex: 'Player',
      render: renderInfo,
    },
    {
      title: '礼物数量',
      width: 100,
      align: 'center',
      dataIndex: 'giftCount',
    },
    {
      title: '投票数',
      width: 100,
      align: 'center',
      dataIndex: 'ticketCount',
    },
    {
      title: '投票时间',
      width: 100,
      align: 'center',
      dataIndex: 'createdAt',
      render: (e) => moment(e).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={state.data.data}
      pagination={{ current: page, pageSize: PAGE_SIZE, total: state.data.count }}
      onChange={handleTableChange}
      loading={state.loading}
      columns={columns}
    />
  );
}

export default MainTable;
