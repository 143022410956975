import React, { useState } from 'react';
import { Col, Button } from 'antd';
import { Formik } from 'formik';
import { Input } from '@jbuschke/formik-antd';
import MainTable from './components/MainTable';
import AddUser from './components/AddUser';
import styles from './index.module.scss';

function UserList() {
  const [flag, setFlag] = useState(false);
  const [datas, setDatas] = useState({});
  const [param, setParam] = useState({});
  const [visible, setVisible] = useState(false);

  return (
    <div className={styles.page}>
      <Formik
        initialValues={param}
        onSubmit={(val) => setParam(val)}
        render={props => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Col>
              <Input
                name="id"
                className={styles.inputStyle}
                placeholder="请输入用户ID"
              />
              <Input
                name="name"
                className={styles.inputStyle}
                placeholder="请输入用户名"
              />
              <Input
                name="phonenum"
                className={styles.inputStyle}
                placeholder="请输入手机号"
              />
              <Button
                type="primary"
                block
                className={styles.subBtnStyle}
                onClick={props.handleSubmit}
              >
                查询
              </Button>
            </Col>
            <Button
              type="primary"
              onClick={() => setVisible(true)}
            >
              添加用户
            </Button>
          </div>
        )}
      />
      <div className={styles.mainTable}>
        <MainTable
          param={param}
          flag={flag}
          onChange={(res) => {
            setDatas(res);
            setVisible(true);
          }}
        />
      </div>
      <AddUser
        datas={datas}
        visible={visible}
        onChange={(res) => {
          setVisible(false);
          setDatas({});
          if (res) setFlag(e => !e);
        }}
      />
    </div>
  );
}

export default UserList;
