import React from 'react';
import PropTypes from 'prop-types';

function format(url) {
  return url.match(/(http|https):\/\/([\w.]+\/?)\S*/) ? url : `//${url}`;
}
function LinkInput({ url }) {
  return (
    <a
      href={format(url)}
      target="_Blank"
      rel="noopener noreferrer"
    >
      点击打开
    </a>
  );
}
LinkInput.propTypes = {
  url: PropTypes.string,
};
export default LinkInput;
