import React from 'react';
import { Layout, Menu, Icon, Dropdown, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';
import Logo from './Logo';
import { HEADER_MENUS } from '@/utils/constants';
import SelectArea from './SelectArea';
import useAppContext from '@/useAppContext';
import useReactRouter from 'atv-use-react-router';
import { getAuth } from '@/utils/app';

export default function Header(props) {
  const { isMobile } = props;
  const { logout, authValue } = useAppContext();
  const { history } = useReactRouter();
  return (
    <Layout.Header className={styles.header}>
      <div className={styles.headerLeft}>
        <div>
          <Logo />
          <SelectArea />
        </div>
      </div>

      <div className={styles.headerMenu}>
        {HEADER_MENUS && HEADER_MENUS.length > 0 ? (
          <Menu mode="horizontal" selectedKeys={[]}>
            {HEADER_MENUS.map((nav, idx) => {
              const linkProps = {};
              if (nav.newWindow) {
                linkProps.href = nav.path;
                linkProps.target = '_blank';
              } else if (nav.external) {
                linkProps.href = nav.path;
              } else {
                linkProps.to = nav.path;
              }
              return (
                <Menu.Item key={idx} className={styles.headerMenuItem}>
                  {linkProps.to
                    ? (
                      <Link {...linkProps}>
                        {nav.icon ? (
                          <Icon type={nav.icon} size="small" />
                        ) : null}
                        <span className={styles.natText}>{!isMobile ? nav.name : null}</span>
                      </Link>
                    )
                    : (
                      <a {...linkProps}>
                        {nav.icon ? (
                          <Icon type={nav.icon} size="small" />
                        ) : null}
                        <span className={styles.natText}>{!isMobile ? nav.name : null}</span>
                      </a>
                    )}
                </Menu.Item>
              );
            })}
          </Menu>
        ) : null}

        <Dropdown
          overlay={(
            <Menu className={styles.userInfoDropdownMenu}>
              <Menu.Item>
                <div onClick={() => { history.push('/managers/' + getAuth().id, 'fromHeader'); }}>
                  <Icon type="user" size="small" />
                  账户
                </div>
              </Menu.Item>
              <Menu.Item>
                <div onClick={() => logout()}>
                  <Icon type="logout" size="small" />
                  退出
                </div>
              </Menu.Item>
            </Menu>
          )}
        >
          <div className={styles.userInfo}>
            <Avatar
              size={25}
              icon="user"
            />
            <div className={styles.userProfile}>
              <span className={styles.userName}>{authValue.name}</span>
            </div>
            <Icon type="caret-down" className={styles.downIcon} />

          </div>
        </Dropdown>
      </div>
    </Layout.Header>
  );
}
