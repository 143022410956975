import React from 'react';
import styles from './AccurateInfo.module.scss';

const AccurateInfo = (props) => {
  return (
    <div className={styles.basicInfoContainer}>
      <div className={styles.selfIntroduction}>
        <p className={styles.titleIntroduction}>详细资料</p>
        <div className={styles.padBetweenLine}></div>
        <div className={styles.infoShow}>
          <div style={{ flex: 1 }}>
            <p>身高(CM): {props.height || ''}</p>
            <p>鞋码(EU): {props.shoeSize || ''}</p>
            <p>直播平台: {props.live}</p>
            <p>就读学校: {props.school}</p>
            <p>才艺特长: {props.speciality}</p>
          </div>
          <div style={{ flex: 1 }}>
            <p>体重(KG): {props.weight || ''}</p>
            <p>衣码(SIZE): {props.clothingSize || ''}</p>
            <p>职业: {props.company}</p>
            <p>最高学历: {props.education}</p>
            <p>邮箱: {props.email}</p>
          </div>
          <div style={{ flex: 1 }}>
            <p>三围: {props.bust && props.waist && props.hips ? props.bust + '-' + props.waist + '-' + props.hips : ''}</p>
            <p>所学专业: {props.major}</p>
            <p>所获奖项: {props.prize}</p>
            <p>直播平台ID: {props.liveId}</p>
          </div>
        </div>
      </div>
      <div className={styles.selfIntroduction}>
        <p className={styles.titleIntroduction}>个人介绍</p>
        <div className={styles.padBetweenLine}></div>
        <p>{props.description}</p>
      </div>
    </div>
  );
};

export default AccurateInfo;
