import React, { useState } from 'react';
import TableHeader from '@/components/TableHeader';
import MainTable from './components/MainTable';
import useReactRouter from 'atv-use-react-router';
import { message } from 'antd';

import styles from './index.module.scss';

function SponsorList() {
  const { history } = useReactRouter();
  const [isFull, setIsFull] = useState(false);
  let newLevel;
  const handleAddBtnClick = () => {
    isFull ? message.error('赞助商最多十二个') : history.push('/sponsors/new', newLevel);
  };
  return (
    <div className={styles.page}>
      <TableHeader name="赞助商" onAddBtnClick={handleAddBtnClick} />
      <div className={styles.mainTable}>
        <MainTable setIsFull={setIsFull} setNewLevel={v => { newLevel = v; }} />
      </div>
    </div>
  );
}

export default SponsorList;
