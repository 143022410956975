import { ResponseError } from 'atv-make-request-factory';
import { AUTH_KEY, GAMEAREA_KEY } from '@/utils/constants';

// 登录信息
let auth;

// 赛区
let gameAreaId;

export function getAuth() {
  if (auth) {
    if (auth.expiredAt < Date.now()) {
      return false;
    }
    return auth;
  }
  const rawAuth = localStorage.getItem(AUTH_KEY);
  if (!rawAuth) {
    return false;
  }
  try {
    const parsedAuth = JSON.parse(rawAuth);
    if (parsedAuth.expiredAt < Date.now()) {
      return false;
    }
    auth = parsedAuth;
    return auth;
  } catch (err) {
    return false;
  }
}

export function withJWTHeader(options) {
  const auth = getAuth();
  if (!auth) {
    return;
  }
  if (!options.headers) options.headers = {};
  options.headers['Authorization'] = 'Bearer ' + auth.token;
}

export function saveAuth(authValue, persist = false) {
  auth = authValue;
  if (persist) {
    localStorage.setItem(AUTH_KEY, JSON.stringify(auth));
  } else {
    localStorage.removeItem(AUTH_KEY);
  }
}

export function postFetch(res) {
  if (res instanceof ResponseError && res.response.status === 401) {
    return toLogin();
  }
  return res;
}

export function getGameAreaId() {
  if (gameAreaId) {
    return gameAreaId;
  }
  const gameAreaIdRaw = localStorage.getItem(GAMEAREA_KEY);
  if (!gameAreaIdRaw) {
    return null;
  }
  gameAreaId = parseInt(gameAreaIdRaw);
  return gameAreaId;
}

export function saveGameAreaId(id) {
  gameAreaId = id;
  localStorage.setItem(GAMEAREA_KEY, id);
}

export function clearAuth() {
  auth = null;
  localStorage.removeItem(AUTH_KEY);
}

export function toLogin() {
  window.location.href = getUrlPrefix() + 'auth/login';
}

export function getUrlPrefix() {
  const prefix = process.env.PUBLIC_URL;
  if (!prefix) {
    return '/';
  }
  if (prefix.slice(-1) === '/') {
    return prefix;
  }
  return prefix + '/';
}
