import { Button, Col } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Input } from '@jbuschke/formik-antd';
import MainTable from './components/MainTable';
import styles from './index.module.scss';

function Statistics() {
  const [playerId, setPlayerId] = useState('');

  // const handleExport = () => {
    
  // }

  return (
    <div className={styles.page}>
      <Formik
        initialValues={{ playerId }}
        onSubmit={(e) => setPlayerId(e.playerId)}
        render={props => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Col>
              <Input
                name="playerId"
                className={styles.inputStyle}
                placeholder="请输入选手ID"
              />
              <Button
                type="primary"
                block
                className={styles.subBtnStyle}
                onClick={props.handleSubmit}
              >
                查询
              </Button>
            </Col>
            {/* <Button
              type="primary"
              onClick={handleExport}
            >
              导出
            </Button> */}
          </div>
        )}
      />
      <div className={styles.mainTable}>
        <MainTable playerId={playerId} />
      </div>
    </div>
  );
}

export default Statistics;
