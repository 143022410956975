import React from 'react';
import { Table } from 'antd';
import Thumbnails from '@/components/Thumbnails';
import ActionsEditDelete from '@/components/ActionsEditDelete';
import useReactRouter from 'atv-use-react-router';
import { listManageAccount, deleteManageAccount } from '@/utils/api';
import { useTableHelper, useEnsureGameArea, useUpdateEffect } from '@/utils/hooks';

const PAGE_SIZE = 8;

export default function MainTable() {
  const gameAreaId = useEnsureGameArea();
  const callDeleteApi = record => deleteManageAccount({ params: { id: record.id } });
  const q = JSON.stringify({ gameAreaId });
  const { state, page, handlePaginate, handleDelete } = useTableHelper(listManageAccount, callDeleteApi, {
    pageSize: PAGE_SIZE, q });
  const { history } = useReactRouter();

  useUpdateEffect(() => {
    handlePaginate(1);
  }, [gameAreaId]); // eslint-disable-line

  const handleTableChange = pagination => {
    handlePaginate(pagination.current);
  };

  const handleEdit = record => {
    history.push('/managers/' + record.id);
  };

  const columns = [
    {
      title: '序号',
      width: 60,
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: '名称',
      width: 100,
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '配图',
      width: 100,
      align: 'center',
      dataIndex: 'avatarUrl',
      render: v => <Thumbnails images={[v]} center />,
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      render: (text, record) =>
        <ActionsEditDelete
          onEditBtnClick={() => handleEdit(record)}
          onDeleteBtnclick={() => handleDelete(record)}
        />,
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={state.data.data}
      pagination={{ current: page, pageSize: PAGE_SIZE, total: state.data.count }}
      onChange={handleTableChange}
      loading={state.loading}
      columns={columns}
    />
  );
}
