import React, { useState } from 'react';
import MainTable from './components/MainTable';
import styles from './index.module.scss';
import { Formik } from 'formik';
import { Input } from '@jbuschke/formik-antd';
import { Row, Col, Button } from 'antd';

function PlayerList() {
  const [name, setName] = useState(null);
  return (
    <div className={styles.page}>
      <Formik
        initialValues={{ name: '' }}
        onSubmit={(values) => {
          setName(values.name);
        }}
        render={props => (
          <div>
            <Row>
              <Col>
                <Input
                  className={styles.inputStyle}
                  name="name"
                  type=""
                  placeholder="请输入选手姓名"
                />
                <Button
                  type="primary"
                  block
                  className={styles.subBtnStyle}
                  onClick={props.handleSubmit}
                >
                  查询
                </Button>
              </Col>
            </Row>
          </div>
        )}
      />
      <div className={styles.mainTable}>
        <MainTable name={name || ''} />
      </div>
    </div>
  );
}

export default PlayerList;
