import React, { useEffect } from 'react';
import { Modal, Table, message } from 'antd';
import { updateJudgeSort, listJudge, updateJudge } from '@/utils/api';
import ActionsEditDelete from '@/components/ActionsEditDelete';
import useReactRouter from 'atv-use-react-router';
import {
  useTableHelper,
  useEnsureGameArea,
  useGenericErrorHandler,
  useUpdateEffect,
} from '@/utils/hooks';
import OrderLevel from '@/pages/JudgeList/components/OrderLevel';
import useFetch from 'atv-use-fetch';
import Thumbnails from '@/components/Thumbnails';

const PAGE_SIZE = 8;
const { confirm } = Modal;
function DeleteModal(handleDelete, record) {
  confirm({
    title: '确认删除此评委？',
    okText: '确定',
    okType: 'danger',
    cancelText: '取消',
    icon: <i></i>,
    onOk() {
      record && handleDelete(record);
      message.success('删除成功');
    },
    onCancel() {},
  });
}

export default function MainTable() {
  const gameAreaId = useEnsureGameArea();
  const handleError = useGenericErrorHandler();
  const callDeleteApi = record =>
    updateJudge({ params: { id: record.id }, body: { status: 0 } });
  const q = JSON.stringify({ gameAreaId, status: 1 });
  const o = '["sortValue"]';
  const { state, page, handlePaginate, handleDelete } = useTableHelper(
    listJudge,
    callDeleteApi,
    { pageSize: PAGE_SIZE, q, o }
  );
  const { history } = useReactRouter();

  useUpdateEffect(() => {
    handlePaginate(1);
  }, [gameAreaId]); // eslint-disable-line

  useEffect(() => {
    return () => Modal.destroyAll();
  }, []);

  const handleTableChange = pagination => {
    handlePaginate(pagination.current);
  };

  const { state: changeLevelState, callApi } = useFetch(
    updateJudgeSort,
    {},
    () => {},
    () => {
      handlePaginate(1);
      message.success('修改成功');
    },
    err => handleError(err)
  );

  function MarkFirstLast(data) {
    if (data.length) {
      data[0].first = true;
      data[data.length - 1].last = true;
      return data;
    } else {
      return data;
    }
  }

  const handleEdit = record => {
    history.push('/judges/' + record.id);
  };

  const columns = [
    {
      title: '序号',
      width: 60,
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: '头像',
      width: 100,
      align: 'center',
      dataIndex: 'avatarUrl',
      render: v => <Thumbnails images={[v]} center />,
    },
    {
      title: '名字',
      width: 100,
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '评委头衔',
      width: 100,
      align: 'center',
      dataIndex: 'title',
    },
    {
      title: '简介',
      width: 200,
      align: 'center',
      dataIndex: 'description',
      render: (e) => e.trim() || '-'
    },
    {
      title: '排序',
      width: 100,
      align: 'center',
      render: (text, record) => (
        <OrderLevel
          record={record}
          onUp={() => {
            const id = record.id;
            const data = state.data.data;
            const targetIndex = data.findIndex(
              ({ id: targetId }) => targetId === id
            );
            if (targetIndex === 1) {
              callApi({
                body: {
                  gameAreaId: gameAreaId,
                  id: id, // description="评委id"
                  bottomId: data[targetIndex - 1].id, // optional description="移动的位置上面的评委id 底部 不用传"
                },
              });
            } else {
              callApi({
                body: {
                  gameAreaId: gameAreaId,
                  id: id, // description="评委id"
                  bottomId: data[targetIndex - 1].id, // optional description="移动的位置上面的评委id 顶部 不用传"
                  upId: data[targetIndex - 2].id, // optional description="移动的位置上面的评委id 底部 不用传"
                },
              });
            }
          }}
          onDown={() => {
            const id = record.id;
            const data = state.data.data;
            const targetIndex = data.findIndex(
              ({ id: targetId }) => targetId === id
            );
            if (targetIndex === data.length - 2) {
              callApi({
                body: {
                  gameAreaId: gameAreaId,
                  id: id, // description="评委id"
                  upId: data[targetIndex + 1].id, // optional description="移动的位置上面的评委id 底部 不用传"
                },
              });
            } else {
              callApi({
                body: {
                  gameAreaId: gameAreaId,
                  id: id, // description="评委id"
                  upId: data[targetIndex + 1].id, // optional description="移动的位置上面的评委id 顶部 不用传"
                  bottomId: data[targetIndex + 2].id, // optional description="移动的位置上面的评委id 底部 不用传"
                },
              });
            }
          }}
        />
      ),
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      render: (text, record) => (
        <ActionsEditDelete
          onEditBtnClick={() => handleEdit(record)}
          onDeleteBtnclick={() => DeleteModal(handleDelete, record)}
        />
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={MarkFirstLast(state.data.data)}
      pagination={{
        current: page,
        pageSize: PAGE_SIZE,
        total: state.data.count,
      }}
      onChange={handleTableChange}
      loading={state.loading || changeLevelState.loading}
      columns={columns}
    />
  );
}
