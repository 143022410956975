import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { listGiftcharge } from '@/utils/api';
import {
  useEnsureGameArea,
  useTableHelper,
  useUpdateEffect,
} from '@/utils/hooks';

const PAGE_SIZE = 10;

export default function MainTable({ param = {} }) {
  const gameAreaId = useEnsureGameArea();
  const { id, money, status } = param;
  const qValue = {
    gameAreaId,
  };
  if (id) {
    qValue.id = id;
  }
  if (typeof money === 'number') {
    qValue.money = { $gt: money * 100 };
  }
  if (typeof status === 'number') {
    qValue.status = status;
  }
  const q = JSON.stringify(qValue);
  const { state, page, handlePaginate } = useTableHelper(
    listGiftcharge,
    () => {},
    {
      pageSize: PAGE_SIZE,
      q,
    }
  );

  useUpdateEffect(() => {
    console.log(money, status);
    handlePaginate(1);
  }, [param]); // eslint-disable-line

  const handleTableChange = (pagination) => {
    handlePaginate(pagination.current);
  };

  const columns = [
    {
      title: '订单ID',
      width: 100,
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: '禮物名稱',
      width: 60,
      align: 'center',
      render: (e) => {
        const data = JSON.parse(e.gift) || {};
        return data.name || '-';
      },
    },
    {
      title: '禮物價格',
      width: 60,
      align: 'center',
      render: (e) => {
        const data = JSON.parse(e.gift) || {};
        return (data.price / 100).toFixed(2) || 0;
      },
    },
    {
      title: '購買數量',
      width: 60,
      align: 'center',
      render: (e) => {
        const data = JSON.parse(e.gift) || {};
        return data.num || 0;
      },
    },
    {
      title: '支付方式',
      width: 60,
      align: 'center',
      dataIndex: 'channelExtra',
      render: (e, ev) =>
        ev.money !== 0 ? (e === 'paypal' ? 'paypal' : '支付寶') : '免費贈送',
    },
    {
      title: '支付總計',
      width: 60,
      align: 'center',
      dataIndex: 'money',
      render: (e) => (e / 100).toFixed(2),
    },
    {
      title: '贈送選手',
      width: 60,
      align: 'center',
      dataIndex: 'Player',
      render: (e) => (e && e.name) || '-',
    },
    {
      title: '下單用戶',
      width: 60,
      align: 'center',
      dataIndex: 'User',
      render: (e) => (e && e.name) || '-',
    },
    {
      title: '支付狀態',
      width: 60,
      align: 'center',
      dataIndex: 'status',
      render: (e) => (e === 1 ? '已支付' : '未支付'),
    },
    {
      title: '創建時間',
      width: 60,
      align: 'center',
      dataIndex: 'createdAt',
      render: (e) => moment(e).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  return (
    <Table
      rowKey='id'
      dataSource={state.data.data}
      pagination={{
        current: page,
        pageSize: PAGE_SIZE,
        total: state.data.count,
      }}
      onChange={handleTableChange}
      loading={state.loading}
      columns={columns}
      footer={() => `訂單金額總計：${(state.data.allMoney / 100).toFixed(2)}`}
    />
  );
}
