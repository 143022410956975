import React, { useState } from 'react';
import { Button, Table } from 'antd';
import { listMPlayers } from '@/utils/api';
import Thumbnails from '@/components/Thumbnails';
import useReactRouter from 'atv-use-react-router';
import { useTableHelper, useEnsureGameArea, useUpdateEffect } from '@/utils/hooks';
import SignUp from '@/pages/ApplyList/components/SignUp';

const PAGE_SIZE = 8;

function MyTable({ name }) {
  const [id, setId] = useState(null);
  const [visible, setVisible] = useState(false);
  const gameAreaId = useEnsureGameArea();
  const qValue = { status: 1, gameAreaId };
  if (name.length !== 0) {
    qValue.name = { $like: `%${name}%` };
  }
  const q = JSON.stringify(qValue);
  const { state, page, handlePaginate } = useTableHelper(listMPlayers, () => {}, { pageSize: PAGE_SIZE, q });
  const { history } = useReactRouter();

  useUpdateEffect(() => {
    handlePaginate(1);
  }, [gameAreaId, name]); // eslint-disable-line

  const handleTableChange = pagination => {
    handlePaginate(pagination.current);
  };

  const columns = [
    {
      title: 'ID',
      width: 60,
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: '编号',
      width: 100,
      align: 'center',
      dataIndex: 'no',
    },
    {
      title: '图片',
      width: 100,
      align: 'center',
      dataIndex: 'avatarUrl',
      render: v => <Thumbnails images={[v]} center />,
    },
    {
      title: '姓名',
      width: 100,
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '状态',
      width: 100,
      align: 'center',
      dataIndex: 'status',
      render: v => ['待审核', '通过', '拒绝'][v],
    },
    {
      title: '排名',
      width: 100,
      align: 'center',
      dataIndex: 'index',
    },
    {
      title: '等级',
      width: 100,
      align: 'center',
      dataIndex: 'title',
    },
    {
      title: '票数',
      width: 100,
      align: 'center',
      dataIndex: 'receiveGiftVotes',
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      render: e => (
        <>
          <Button
            type='link'
            onClick={() => history.push('/players/' + e.id)}
          >
            查看
          </Button>
          <Button
            type='link'
            onClick={(event) => {
              event.preventDefault();
              setId(e.id);
              setVisible(true);
            }}
          >
            修改
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        loading={state.loading}
        dataSource={state.data.data}
        onChange={handleTableChange}
        pagination={{ current: page, pageSize: PAGE_SIZE, total: state.data.count }}
      />
      <SignUp
        id={id}
        visible={visible}
        onChange={(res) => {
          setId(null);
          setVisible(false);
          if (res) handlePaginate(1);
        }}
      />
    </>
  );
}

export default MyTable;
