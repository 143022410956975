import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popconfirm } from 'antd';
import styles from './index.module.scss';

export default function ActionsEditDelete(props) {
  return (
    <div className={styles.btns}>
      <Icon className={styles.editBtn} type="edit" onClick={props.onEditBtnClick} />
      <Popconfirm
        title="确认删除该数据？"
        okText="确认"
        cancelText="取消"
        onConfirm={props.onDeleteBtnclick}
      >
        <Icon className={styles.deleteBtn} type="delete" />
      </Popconfirm>
    </div>
  );
}

ActionsEditDelete.propTypes = {
  onDeleteBtnclick: PropTypes.func,
  onEditBtnClick: PropTypes.func,
};
