import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { message } from 'antd';
import routes from './routes';
import useAppContext from './useAppContext';
import { getUrlPrefix } from './utils/app';

message.config({ maxCount: 1 });

function App() {
  return (
    <useAppContext.Provider>
      <BrowserRouter basename={getUrlPrefix()}>
        {renderRoutes(routes)}
      </BrowserRouter>
    </useAppContext.Provider>
  );
}

export default App;
