import React from 'react';
import { Layout } from 'antd';
import { renderRoutes } from 'react-router-config';
import styles from './index.module.scss';

export default function Auth({ route }) {
  return (
    <Layout className={styles.userLayout}>
      <div className={styles.layerMask} />
      <div className={styles.userContent}>
        {renderRoutes(route.routes)}
      </div>
    </Layout>
  );
}
