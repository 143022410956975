import React from 'react';
import Home from '@/pages/Home';
import Basic from '@/layouts/Basic';
import Auth from '@/layouts/Auth';
import Login from '@/pages/Login';
import FontIcon from '@/components/FontIcon';
import GameAreaList from '@/pages/GameAreaList';
import GameAreaDetail from '@/pages/GameAreaDetail';
import MentorDetail from '@/pages/MentorDetail';
import PlayerList from '@/pages/PlayerList';
import PlayerDetail from '@/pages/PlayerDetail';
import ApplyList from '@/pages/ApplyList';
import LiveList from '@/pages/LiveList';
import LiveDetail from '@/pages/LiveDetail';
import JudgeList from '@/pages/JudgeList';
import JudgeDetail from '@/pages/JudgeDetail';
import SponsorList from '@/pages/SponsorList';
import SponsorDetail from '@/pages/SponsorDetail';
import EventList from '@/pages/EventList';
import EventDetail from '@/pages/EventDetail';
import ManagerList from '@/pages/ManagerList';
import ManagerDetail from '@/pages/ManagerDetail';
import GameAreaMask from '@/pages/GameAreaMask';
import Settings from '@/pages/Settings';
import UserList from '@/pages/UserList';
import Statistics from '@/pages/Statistics';
import OrderList from '@/pages/OrderList';

export const userRoutes = {
  path: '/auth/',
  component: Auth,
  routes: [
    {
      path: '/auth/login',
      exact: true,
      component: Login,
    },
  ],
};

export const basicRoutes = {
  component: Basic,
  routes: [
    {
      path: '/',
      exact: true,
      icon: <FontIcon type="dashboard" />,
      name: '概况',
      component: Home,
    },
    {
      path: '/managers',
      exact: true,
      icon: <FontIcon type="manager" />,
      name: '子账户',
      component: ManagerList,
    },
    {
      path: '/managers/:id',
      exact: true,
      component: ManagerDetail,
    },
    {
      path: '/gameAreas',
      exact: true,
      icon: <FontIcon type="area" />,
      name: '赛区',
      component: GameAreaList,
    },
    {
      path: '/gameAreas/:id',
      exact: true,
      component: GameAreaDetail,
    },
    {
      path: '/mentors/:id',
      exact: true,
      component: MentorDetail,
    },
    {
      path: '/players',
      exact: true,
      icon: <FontIcon type="player" />,
      name: '选手',
      component: PlayerList,
    },
    {
      path: '/players/:id',
      exact: true,
      component: PlayerDetail,
    },
    {
      path: '/applys',
      exact: true,
      icon: <FontIcon type="apply" />,
      name: '审核',
      component: ApplyList,
    },
    {
      path: '/statistics',
      exact: true,
      icon: <FontIcon type="dashboard" />,
      name: '统计',
      component: Statistics,
    },
    {
      path: '/orderlist',
      exact: true,
      icon: <FontIcon type="dashboard" />,
      name: '订单',
      component: OrderList,
    },
    {
      path: '/applys/:id',
      exact: true,
      component: PlayerDetail,
    },
    {
      path: '/lives',
      exact: true,
      icon: <FontIcon type="play" />,
      name: '直播',
      component: LiveList,
    },
    {
      path: '/lives/:id',
      exact: true,
      component: LiveDetail,
    },
    {
      path: '/judges',
      exact: true,
      icon: <FontIcon type="judge" />,
      name: '评委',
      component: JudgeList,
    },
    {
      path: '/judges/:id',
      exact: true,
      component: JudgeDetail,
    },
    {
      path: '/sponsors',
      exact: true,
      icon: <FontIcon type="sponsor" />,
      name: '赞助商',
      component: SponsorList,
    },
    {
      path: '/sponsors/:id',
      exact: true,
      component: SponsorDetail,
    },
    {
      path: '/events',
      exact: true,
      icon: <FontIcon type="events" />,
      name: '动态',
      component: EventList,
    },
    {
      path: '/user',
      exact: true,
      icon: <FontIcon type="player" />,
      name: '用户',
      component: UserList,
    },
    {
      path: '/events/:id',
      exact: true,
      component: EventDetail,
    },
    {
      path: '/gameAreaMask',
      exact: true,
      component: GameAreaMask,
    },
    {
      path: '/sysconfig',
      exact: true,
      icon: <FontIcon type="settings" />,
      name: '设置',
      component: Settings,
    },
  ],
};

export default [userRoutes, basicRoutes];
