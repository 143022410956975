import React, { useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import { Input } from '@jbuschke/formik-antd';
import { Row, Col, Icon, Button } from 'antd';
import useFetchOnce from 'atv-use-fetch-once';
import { listMPlayers } from '@/utils/api';
import Loading from '@/components/Loading';
import ChoosePlayer from './components/ChoosePlayer';

import styles from './index.module.scss';

const { TextArea } = Input;

function MentorDetail() {
  const state = useFetchOnce(listMPlayers, {});
  const [visible, setVisible] = useState(false);
  const [playLists, setPlayLists] = useState([{ avatarUrl: 'http://static.ymhl.net.cn/Llo26vhAYvp9iIPl2U72YmXvOEiz0xnhJNkDwBrD.jpeg' }, { avatarUrl: 'http://static.ymhl.net.cn/Llo26vhAYvp9iIPl2U72YmXvOEiz0xnhJNkDwBrD.jpeg' }]);

  const renderErrMsg = msg => <div className={styles.errorMsg}>{msg}</div>;

  function handleShowModal() {
    setVisible(!visible);
  }

  function handleCancel() {
    setVisible(false);
  }

  function handleOk() {
    setVisible(false);
  }

  function handleChoosePlayer(item) {
    setPlayLists([...playLists, { ...item }]);
  }
  function handleCancelChoosePlayer(item) {

    // setPlayLists([...playLists, { ...item}]);
  }

  function showPlayerLists() {
    return (
      <div className={styles.flexRow}>
        {
          playLists.length > 0 ? playLists.map((item, index) => {
            return (
              <div key={index} className={styles.ImageWarp}>
                <div className={styles.ImageRelative}>
                  <img className={styles.image} src={item.avatarUrl} alt="" />
                  <Icon className={styles.icon} type='close-circle' theme="filled" />
                </div>
              </div>
            );
          }) : ''
        }
      </div>
    );
  }

  if (state && state.loading) {
    return (
      <div><Loading /></div>
    );
  }

  return (
    <div className={styles.formContainer}>
      <Formik
        initialValues={{ name: '', teamName: '', gameAreaId: 1, avatarUrl: '', description: '', role: '', imgUrl: '' }}
        onSubmit={(values, actions) => {
        }}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = '必填';
          }
          if (!values.teamName) {
            errors.teamName = '必填';
          }
          if (!values.description) {
            errors.description = '必填';
          }
          return errors;
        }}
        render={props => (
          <div>
            <Row className={styles.formItem}>
              <Col className={styles.formItemCol}>
                <div className={styles.formLabel}>导师名称:</div>
                <Input
                  className={styles.input}
                  name="name"
                  placeholder="请输入导师名称"
                />
              </Col>
              <Col>
                <ErrorMessage render={renderErrMsg} name="name" />
              </Col>
              <Col className={styles.formItemCol}>
                <div className={styles.formLabel}>导师宣言:</div>
                <TextArea
                  className={styles.textArea}
                  rows={4}
                  name="description"
                  placeholder="请输入导师宣言"
                />
              </Col>
              <Col>
                <ErrorMessage render={renderErrMsg} name="description" />
              </Col>
            </Row>

            <Row className={styles.formItem}>
              <Col className={styles.formItemCol}>
                <div className={styles.formLabel}>战队名称:</div>
                <Input
                  className={styles.input}
                  name="teamName"
                  placeholder="请输入战队名称"
                />
              </Col>
              <Col>
                <ErrorMessage render={renderErrMsg} name="teamName" />
              </Col>
              <div className={styles.buttons}>
                <Button
                  onClick={props.handleSubmit}
                  className={styles.submitBtn}
                >
                  提交表单
                </Button>
                <Button
                  className={styles.backBtn}
                >
                  返回列表
                </Button>
              </div>
            </Row>
            <Row className={styles.formChoosePlayer}>
              <Col className={styles.formItemCol}>
                <div className={styles.formLabel}>添加选手:</div>
                {showPlayerLists()}
                <div className={styles.formLabel}>
                  共{playLists.length}名选手
                </div>
              </Col>
              <Button onClick={handleShowModal} className={styles.addPlayer}>
                <Icon type="plus-circle" style={{ color: '#dfb854', fontSize: '18px' }} /> 添加选手
              </Button>

            </Row>
          </div>
        )}
      />

      <ChoosePlayer
        playerLists={(state && state.loading === false) ? state.data.data : []}
        visible={visible}
        handleCancel={handleCancel}
        handleOk={handleOk}
        handleChoosePlayer={handleChoosePlayer}
        handleCancelChoosePlayer={handleCancelChoosePlayer}
      />
    </div>
  );
}

export default MentorDetail;
