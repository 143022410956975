import React from 'react';
import styles from './PlayerProduce.module.scss';
import FormHeader from '@/components/FormHeader';
import useRouter from 'atv-use-react-router';
import VideoPlayer from './VideoPlayer';
const PlayerProduce = (props) => {
  console.log(props.images)
  const { history } = useRouter();
  let names = [];
  let des = '';
  switch (props.source) {
    case 'applys':
      names = ['待审核列表', '待审核详情'];
      des = 'applys';
      break;
    case 'players':
      names = ['选手列表', '选手详情'];
      des = 'players';
      break;
    case 'unpassed':
      names = ['未通过列表', '未通过详情'];
      des = 'applys';
      break;
    default:
      break;
  }
  const formHeaderClick = (i) => {
    i === 0 && history.push({ pathname: `${'/' + des}` });
  };

  return (
    <div className={styles.produceContainer}>
      <FormHeader names={names} onClick={formHeaderClick} />
      <div className={styles.bodyShow}>
        <img className={styles.playerImg} src={props.avatarUrl} alt='选手头像' />
        <div className={styles.playerInfos}>
          <p className={styles.playerName}>
            <span>{props.name}</span>
            <span className={styles.playerID}>ID: {props.id}</span>
          </p>
          {
            props.source === 'applys' ? null : <div>
              <p>
                <span className={styles.playerLevel}>{props.no}号{props.title}</span>
              </p>
              <div>
                <span>排名: {props.index}</span>
                <span className={styles.leftPadding}>票数: {props.receiveGiftVotes}</span>
              </div>
            </div>
          }
        </div>
      </div>
      {props.videoUrl && <div className={styles.gapBetweenLine}></div>}
      {props.videoUrl &&
        <div className={styles.footerShow}>
          <VideoPlayer videoUrl={props.videoUrl} />
        </div>
      }
      {props.images && <div className={styles.gapBetweenLine}></div>}
      <div className={styles.footerShow}>
        {
          props.images.split(',').filter(i => i).map((info, index) => {
            return (
              <div key={index} className={styles.wrapperImg}>
                <img className={styles.headerImg} src={info} alt='' />
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default PlayerProduce;
