import React from 'react';
import { Formik } from 'formik';
import { Input } from '@jbuschke/formik-antd';
import { Row, Col, Button, message } from 'antd';
import { getSponser, addSponser, updateSponser } from '@/utils/api';
import Loading from '@/components/Loading';
import { useIdOrNew, useGenericErrorHandler, useEnsureGameArea } from '@/utils/hooks';
import AliossUploadImage from '@/components/AliossUploadImage';
import H5Editor from '@/components/H5Editor';
import FormHeader from '@/components/FormHeader';
import useFetch, { INITIAL_STATE, ON_NEW_DATA } from 'atv-use-fetch';
import styles from './index.module.scss';
import ErrorMessage from '@/components/FormErrorMessage';
import useReactRouter from 'atv-use-react-router';

const errorMessages = {
  title: '请输入名称',
  url: '请输入链接',
  introduction: '请输入详细内容',
  logoUrl: '请上传logo',
};

const getErrorMessage = (value) => {
  return Object.keys(errorMessages).reduce((result, name) => {
    if (!value[name]) {
      result[name] = errorMessages[name];
    }
    return result;
  }, {});
};

function RenderField({ children, name, customName }) {
  return (
    <div>
      <Col className={styles.formItemCol}>
        <div className={styles.formLabel}>{name}:</div>
        {children}
      </Col>
      <Col className={styles.formItemCol}>
        <div className={styles.buttons}>
          <ErrorMessage name={customName || children.props.name} />
        </div>
      </Col>
    </div>
  );
}

function SponsorDetail() {
  const gameAreaId = useEnsureGameArea();
  const mapResData = res => res;
  const handleError = useGenericErrorHandler();
  const initialValues = {
    title: '',
    url: '',
    introduction: '',
    logoUrl: '',
  };
  const router = useReactRouter();
  if (router.location.pathname === '/sponsors/new') {
    const newLevel = router.location.state;
    newLevel ? initialValues.level = newLevel : initialValues.level = 1;
  }
  const { state, history, id, isNew } = useIdOrNew(getSponser, mapResData, initialValues);
  const { state: mutateState, callApi } = useFetch(
    data => isNew ? addSponser(data) : updateSponser({ params: { id }, ...data }),
    INITIAL_STATE,
    ON_NEW_DATA,
    () => {
      message.success(isNew ? '添加成功' : '修改成功');
      goList();
    },
    err => handleError(err),
  );
  if (state.loading) {
    return <Loading />;
  }
  const goList = () => {
    history.push({ pathname: '/sponsors' });
  };
  return (
    <div>
      <FormHeader
        names={['赞助商列表', '赞助商详情']}
        onClick={e => {
          if (e === 0) {
            goList();
          }
        }}
      />
      <div className={styles.formContainer}>
        <Formik
          initialValues={state.data}
          onSubmit={(values) => {
            callApi({ body: { ...values, gameAreaId, status: 1 } });
          }}
          validate={values => getErrorMessage(values)}
          render={({ values, handleSubmit, setFieldValue }) => (
            <div>
              <Row className={styles.formItem}>
                <RenderField name='名称'>
                  <Input
                    className={styles.input}
                    name="title"
                    placeholder="请输入赞助商名称"
                  />
                </RenderField>
                <Col className={styles.formItemCol}>
                  <div className={styles.formLabel}>logo:</div>
                  <AliossUploadImage
                    defaultValue={values['logoUrl']}
                    onChange={e => setFieldValue('logoUrl', e)}
                  />
                </Col>
                <Col className={styles.formatTips}>请上传一张logo，建议尺寸150 * 150像素，格式png</Col>
                <Col className={styles.formItemCol}>
                  <div className={styles.buttons}>
                    <ErrorMessage name='logoUrl' />
                  </div>
                </Col>
                <RenderField name='链接'>
                  <Input
                    className={styles.input}
                    name="url"
                    placeholder="请输入链接"
                  />
                </RenderField>
                <RenderField name='详细内容' customName='introduction'>
                  <H5Editor
                    defaultValue={values['introduction']}
                    onChange={e => setFieldValue('introduction', e)}
                  />
                </RenderField>
                <div className={styles.buttons}>
                  <Button
                    onClick={handleSubmit}
                    className={styles.submitBtn}
                    loading={mutateState.loading}
                  >
                    提交
                  </Button>
                  <Button
                    className={styles.backBtn}
                    onClick={goList}
                  >
                    返回列表
                  </Button>
                </div>
              </Row>
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default SponsorDetail;
