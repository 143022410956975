import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { ErrorMessage } from 'formik';

export default function FormErrorMessage({ name }) {
  return <ErrorMessage name={name} render={msg => <div className={styles.errorMsg}>{msg}</div>} />;
}

FormErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
};
