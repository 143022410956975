import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Logo.module.scss';

export default function Logo() {
  return (
    <Link to="/" className={styles.logo}>
      <img className={styles.headLogo} src={require('../assets/headLogo.png')} alt='' />
    </Link>
  );
}
