import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import styles from './SelectArea.module.scss';
import { listGameArea } from '@/utils/api';
import useAppContext from '@/useAppContext';
import useReactRouter from 'atv-use-react-router';
import { getAuth } from '@/utils/app';

const { Option } = Select;

export default function SelectArea() {
  const authValue = getAuth();
  const { location } = useReactRouter();
  const { pathname } = location;
  const [gameAreas, setGameAreas] = useState([]);
  const { updateGameArea, gameAreaId } = useAppContext();
  const selectedGameAreaId = gameAreaId || authValue.gameAreaId || '选择赛区';
  useEffect(() => {
    let didCancel = false;

    const fetchGameArea = async () => {
      try {
        const res = await listGameArea({ query: { pageSize: 100, q: JSON.stringify({ status: 1 }) } });
        if (!didCancel) {
          setGameAreas(res.data);
        }
      } catch (err) {
      }
    };

    fetchGameArea();

    return () => {
      didCancel = true;
    };
  }, []);

  const selectArea = id => {
    updateGameArea(id);
  };

  return (
    (pathname === '/' || pathname === '/gameAreas') ? null
      : <div className={styles.selectWrap}>
        <Select
          style={{ width: '130px' }}
          onChange={selectArea}
          disabled={Boolean(authValue.gameAreaId)}
          defaultValue={selectedGameAreaId}
        >
          {
            gameAreas.map((v, i) => (
              <Option
                key={i}
                value={v.id}
              >
                {v.name}
              </Option>
            ))
          }
        </Select>
      </div>
  );
}
