import React from 'react';
import { Button, Table } from 'antd';
import { listUser } from '@/utils/api';
import Thumbnails from '@/components/Thumbnails';
import { useTableHelper, useUpdateEffect } from '@/utils/hooks';

const PAGE_SIZE = 8;

function MyTable({ param = {}, flag, onChange }) {
  const { id, name, phonenum } = param;
  const qValue = {};
  if (name) {
    qValue.name = { $like: `%${name}%` };
  }
  if (phonenum) {
    qValue.phonenum = phonenum;
  }
  if (id) {
    qValue.id = id;
  }
  const q = JSON.stringify(qValue);
  const { state, page, handlePaginate } = useTableHelper(listUser, () => {}, { pageSize: PAGE_SIZE, q });

  useUpdateEffect(() => {
    handlePaginate(1);
  }, [param, flag]); // eslint-disable-line

  const handleTableChange = pagination => {
    handlePaginate(pagination.current);
  };

  const columns = [
    {
      title: 'ID',
      width: 60,
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: '头像',
      width: 100,
      align: 'center',
      dataIndex: 'avatarUrl',
      render: v => <Thumbnails images={[v]} center />,
    },
    {
      title: '名称',
      width: 100,
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '手机号',
      width: 100,
      align: 'center',
      dataIndex: 'phonenum',
      render: e => e || '-',
    },
    {
      title: '是否选手',
      width: 100,
      align: 'center',
      dataIndex: 'isPlayer',
      render: e => e ? '是' : '否',
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      render: e => (
        <Button
          type='link'
          onClick={() => onChange(e)}
        >
          修改
        </Button>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={state.data.data}
      pagination={{ current: page, pageSize: PAGE_SIZE, total: state.data.count }}
      onChange={handleTableChange}
      loading={state.loading}
      columns={columns}
    />
  );
}

export default MyTable;
