import React from 'react';
import { Divider, List } from 'antd';
import './utils.module.scss';

export const formatCardTitle = (title, desc) => {
  const renderTitle = (
    <>
      <Divider
        type="vertical"
        style={{ width: '2px', background: '#1890FF' }}
      />
      <span
        style={{
          fontSize: '14px',
          color: '#1890FF',
          userSelect: 'none',
        }}
      >
        {title}
      </span>
      {desc && (
        <span
          style={{
            fontSize: '12px',
            color: '#B2B2B2',
            paddingLeft: '12px',
            userSelect: 'none',
          }}
        >
          {desc}
        </span>
      )}
    </>
  );
  return {
    title: renderTitle,
    headStyle: { borderBottom: 'none', padding: '0' },
    bodyStyle: { padding: '0 16px 16px' },
    bordered: false,
  };
};

export const formatListParam = (dataSource = []) => {
  const renderItem = item => (
    <List.Item>
      <List.Item.Meta
        avatar={<div
          style={{
            width: '100px',
            height: '32px',
            color: '#333',
            fontSize: '14px',
            display: 'flex',
            justifyContent: 'flex-end',
            lineHeight: '32px',
            userSelect: 'none',
            overflow: 'hidden',
          }}
        >
          {item.r && (
            <span
              style={{
                color: 'red',
                fontSize: '22px',
                lineHeight: '38px',
                marginRight: '2px',
              }}
            >
              *
            </span>
          )}
          {item.l ? `${item.l}：` : ''}
        </div>}
        title={item.v}
      />
    </List.Item>
  );
  return {
    dataSource,
    split: false,
    size: 'small',
    itemLayout: 'horizontal',
    renderItem,
  };
};
