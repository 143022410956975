import React from 'react';
import { Table } from 'antd';
import { useTableHelper } from '@/utils/hooks';
import Thumbnails from '@/components/Thumbnails';
import { listGameArea, updateGameArea } from '@/utils/api';
import ActionsEditDelete from '@/components/ActionsEditDelete';
import useReactRouter from 'atv-use-react-router';

const PAGE_SIZE = 8;

export default function MainTable() {
  const queryValue = { pageSize: PAGE_SIZE, q: JSON.stringify({ status: 1 }) };
  const callDeleteApi = record => updateGameArea({ params: { id: record.id }, body: { status: 0 } });
  const { state, page, handlePaginate, handleDelete } = useTableHelper(listGameArea, callDeleteApi, queryValue);
  const { history } = useReactRouter();

  const handleTableChange = pagination => {
    handlePaginate(pagination.current);
  };

  const handleEdit = record => {
    history.push('/gameAreas/' + record.id);
  };

  const columns = [
    {
      title: '序号',
      width: 60,
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: '配图',
      width: 100,
      align: 'center',
      dataIndex: 'attachmentUrl',
      render: v => <Thumbnails images={[v]} center />,
    },
    {
      title: '规则图',
      width: 100,
      align: 'center',
      dataIndex: 'rule',
      render: v => <Thumbnails images={[v]} center />,
    },
    {
      title: '赛介绍图',
      width: 100,
      align: 'center',
      dataIndex: 'introduce',
      render: v => <Thumbnails images={[v]} center />,
    },
    {
      title: '名称',
      width: 100,
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '标题',
      width: 150,
      align: 'center',
      dataIndex: 'title',
    },
    {
      title: '状态',
      width: 100,
      align: 'center',
      dataIndex: 'status',
      render: v => ['未开始', '进行中', '已完成'][v],
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      render: (text, record) =>
        <ActionsEditDelete
          onEditBtnClick={() => handleEdit(record)}
          onDeleteBtnclick={() => handleDelete(record)}
        />,
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={state.data.data}
      pagination={{ current: page, pageSize: PAGE_SIZE, total: state.data.count }}
      onChange={handleTableChange}
      loading={state.loading}
      columns={columns}
    />
  );
}
