import React, { useState } from 'react';
import FormHeader from '@/components/FormHeader';
import styles from './index.module.scss';
import { Formik } from 'formik';
import ErrorMessage from '@/components/FormErrorMessage';
import { Row, Col, Button, message, Icon } from 'antd';
import { Input } from '@jbuschke/formik-antd';
import AliossUploadImage from '@/components/AliossUploadImage';
import useReactRouter from 'atv-use-react-router';
import { addGameArea, getGameArea, updateGameArea } from '@/utils/api';
import {
  useIdOrNew,
  useGenericErrorHandler,
} from '@/utils/hooks';
import useFetch, {
  INITIAL_STATE,
  ON_NEW_DATA,
} from 'atv-use-fetch';
import Loading from '@/components/Loading';
import ScheduleList from './components/ScheduleList';
import CreateSchedule from './components/CreateSchedule';

const errorMessages = {
  attachmentUrl: '请选择一张图片',
  introduce: '请选择一张赛区介绍图片',
  rule: '请选择一张赛区规则图片',
  name: '内容不能为空！',
  playerShareDesc: '请输入赛区宣言！',
};
const getErrorMessage = (value) => {
  return Object.keys(errorMessages).reduce((result, name) => {
    if (!value[name]) {
      result[name] = errorMessages[name];
    }
    return result;
  }, {});
};
const delecteSchedule = ({ formikProps, id: deleteId, list }) => {
  const { setFieldValue } = formikProps;
  setFieldValue('Schedule', list.filter(({ id }) => id !== deleteId));
};
function GameAreaDetail() {
  const { history } = useReactRouter();
  const [addNewVisible, setAddNewVisible] = useState(false);
  const initialValues = {
    attachmentUrl: null,
    introduce: null,
    rule: null,
    name: '',
    beginAt: null,
    endAt: null,
    playerShareDesc: '',
    times: null,
    Schedule: [],
    title: '',
    deletdId: null,
    waitQueue: {
      CreateType: false,
      times: null,
      name: '',
      beginAt: null,
      endAt: null,
    },
  };
  const mapResData = res => {
    return { ...initialValues, ...res };
  };
  const { state, id, isNew } = useIdOrNew(
    getGameArea,
    mapResData,
    initialValues
  );
  const handleError = useGenericErrorHandler();
  const handleEdit = ({ beginAt, endAt, name, formikProps, id }) => {
    const { setFieldValue } = formikProps;
    setFieldValue('waitQueue', {
      beginAt,
      endAt,
      CreateType: false,
      name,
      id,
    });
    setAddNewVisible(true);
  };
  const showModel = props => {
    const { setFieldValue } = props;
    setFieldValue('waitQueue', {
      beginAt: null,
      endAt: null,
      CreateType: true,
      name: '',
      times: null,
    });
    setAddNewVisible(true);
  };
  const { state: mutateState, callApi } = useFetch(
    data =>
      isNew ? addGameArea(data) : updateGameArea({ params: { id }, ...data }),
    INITIAL_STATE,
    ON_NEW_DATA,
    () => {
      message.success(isNew ? '添加成功' : '修改成功');
      goList();
    },
    err => handleError(err)
  );
  if (state.loading) {
    return <Loading />;
  }
  const formHeaderClick = i => {
    i === 0 && goList();
  };
  const goList = () => {
    history.push({ pathname: '/gameAreas' });
  };
  const handleCancel = () => {
    setAddNewVisible(false);
  };
  const handleOk = () => {
    handleCancel();
  };
  return (
    <div>
      <FormHeader names={['赛区列表', '赛区详情']} onClick={formHeaderClick} />
      <div className={styles.gameAreaDetailWrap}>
        <Formik
          initialValues={state.data}
          validate={getErrorMessage}
          onSubmit={(values, { setFieldValue }) => {
            if (isNew) {
              callApi({
                body: {
                  ...values,
                  // to be comfirm
                  title: values.name,
                  status: 1,
                  type: 1,
                  schedule: values.Schedule,
                },
              });
            } else {
              callApi({
                body: {
                  ...values,
                  // to be comfirm
                  title: values.name,
                  status: 1,
                  type: 1,
                },
              });
            }
          }}
          render={props => (
            <>
              <Row className={styles.formItem}>
                <Col className={styles.formItemCol}>
                  <div className={styles.formLabel}>赛区名称:</div>
                  <div>
                    <Input
                      className={styles.input}
                      name="name"
                      placeholder="请输入赛区名称"
                    />
                    <ErrorMessage name="name" />
                  </div>
                </Col>
                <Col className={styles.formItemCol}>
                  <div className={styles.formLabel}>赛区宣言:</div>
                  <div>
                    <Input
                      className={styles.input}
                      name="playerShareDesc"
                      placeholder="请输入赛区宣言"
                    />
                    <ErrorMessage name="playerShareDesc" />
                  </div>
                </Col>
              </Row>

              <Row className={styles.formItem}>
                <Col className={`${styles.formItemCol} ${styles.gameAreaImg}`}>
                  <div className={styles.formLabel}>配图:</div>
                  <AliossUploadImage
                    name="attachmentUrl"
                    defaultValue={state.data.attachmentUrl}
                    onChange={v => props.setFieldValue('attachmentUrl', v)}
                  />
                </Col>
                <Col className={styles.formatTips}>
                  请上传一张配图，建议尺寸为750*422像素，格式png、jpg
                  <ErrorMessage name="attachmentUrl" />
                </Col>
                <Col className={`${styles.formItemCol} ${styles.gameAreaImg}`}>
                  <div className={styles.formLabel}>规则图:</div>
                  <AliossUploadImage
                    name="rule"
                    defaultValue={state.data.rule}
                    onChange={v => props.setFieldValue('rule', v)}
                  />
                </Col>
                <Col className={styles.formatTips}>
                  请上传一张规则图，此图为长图，建议宽度750，格式png、jpg
                  <ErrorMessage name="rule" />
                </Col>
                <Col className={`${styles.formItemCol} ${styles.gameAreaImg}`}>
                  <div className={styles.formLabel}>赛区介绍图:</div>
                  <AliossUploadImage
                    name="introduce"
                    defaultValue={state.data.introduce}
                    onChange={v => props.setFieldValue('introduce', v)}
                  />
                </Col>
                <Col className={styles.formatTips}>
                  请上传一张赛区介绍图，此图为长图，建议宽度750，格式png、jpg
                  <ErrorMessage name="introduce" />
                </Col>
                <Col className={`${styles.formItemCol} `}>
                  <div className={styles.formLabel}>日程创建：</div>
                  <div>
                    <ScheduleList
                      list={props.values.Schedule}
                      handleEdit={handleEdit}
                      formikProps={props}
                      delecteSchedule={delecteSchedule}
                      isNew={isNew}
                    />
                    <div className={styles.addScheduleBtn} onClick={() => showModel(props)}>
                      <Icon type="plus-circle" className={styles.addMessage} />{' '}
                      添加日程
                    </div>
                    <CreateSchedule
                      isNew={isNew}
                      handleOk={handleOk}
                      handleCancel={handleCancel}
                      formikProps={props}
                      gameAreaId={state.data.id}
                      CreateType={props.values.waitQueue.CreateType}
                      addNewVisible={addNewVisible}
                    />
                  </div>
                </Col>
                <div className={styles.buttons}>
                  <Button
                    onClick={props.handleSubmit}
                    className={styles.submitBtn}
                    loading={mutateState.loading}
                  >
                    提交
                  </Button>
                  <Button
                    className={styles.backBtn}
                    onClick={goList}
                  >
                    返回列表
                  </Button>
                </div>
              </Row>
            </>
          )}
        />
      </div>
    </div>
  );
}

export default GameAreaDetail;
