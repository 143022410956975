import React from 'react';
import { Formik } from 'formik';
import { message, Row, Col, Button, Popover } from 'antd';
import styles from './index.module.scss';
import Loading from '@/components/Loading';
import AliossUploadImage from '@/components/AliossUploadImage/index';
import { useGenericErrorHandler } from '@/utils/hooks';
import FormErrorMessage from '@/components/FormErrorMessage';
import useFetch, { INITIAL_STATE, ON_NEW_DATA } from 'atv-use-fetch';
import { getSysconfig, updateSysconfig } from '@/utils/api';
import useFetchOnce from 'atv-use-fetch-once';

const errorMessages = {
  rule: '请上传赛事规则配图',
};

const getErrorMessage = (value) => {
  return Object.keys(errorMessages).reduce((result, name) => {
    if (!value[name]) {
      result[name] = errorMessages[name];
    }
    return result;
  }, {});
};

export default function Settings() {
  const handleError = useGenericErrorHandler();
  const { data, loading } = useFetchOnce(getSysconfig, { query: { keys: 'competition,exceptionRule,rule' } });
  const { state: mutateState, callApi } = useFetch(
    data => updateSysconfig(data),
    INITIAL_STATE,
    ON_NEW_DATA,
    () => message.success('提交成功'),
    err => handleError(err),
  );
  const { exceptionRule } = data || {};

  return loading ? <Loading /> : (
    <div>
      <div className={styles.formContainer}>
        <Formik
          initialValues={data}
          onSubmit={(values) => {
            callApi({ body: { ...values } });
          }}
          validate={values => getErrorMessage(values) }
          render={props => (
            <div className={styles.formItems}>
              <Row className={styles.formItem}>
                <Col className={styles.formItemCol}>
                  <div className={styles.uploadImg}>
                    <div className={styles.showContent} >
                      <Popover
                        placement="left"
                        content={
                          <div>
                            <img alt='' src={exceptionRule} className={styles.popStyle} />
                          </div>
                        }
                        title=""
                        visible={false}>
                        <div className={styles.imgContent} >
                          <AliossUploadImage
                            defaultValue={exceptionRule || ''}
                            onChange={v => props.setFieldValue('exceptionRule', v)}
                          />
                        </div>
                      </Popover>
                      <p className={styles.titleContent}>免责条约</p>
                      <p className={styles.imgWarning}>点击上传长图</p>
                      <FormErrorMessage name="exceptionRule" />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className={styles.formItem}>
                <Button
                  type="primary"
                  block
                  className={styles.subBtnStyle}
                  loading={mutateState.loading}
                  onClick={props.handleSubmit}
                >
                  提交
                </Button>
              </Row>
            </div>
          )}
        />
      </div>
    </div>
  );
}
