import React from 'react';
import styles from './AboutHer.module.scss';
import { message, Button } from 'antd';
import { useEnsureGameArea, useGenericErrorHandler } from '@/utils/hooks';
import useFetch, { INITIAL_STATE, ON_NEW_DATA } from 'atv-use-fetch';
import { auditPlayer } from '@/utils/api';
import Loading from '@/components/Loading';
import useReactRouter from 'atv-use-react-router';

const AboutHer = (props) => {
  const { history } = useReactRouter();
  const gameAreaId = useEnsureGameArea();
  const handleError = useGenericErrorHandler();
  const { state } = useFetch(auditPlayer);
  const { state: mutateState, callApi } = useFetch(
    data => auditPlayer({ params: { id: props.id }, ...data }),
    INITIAL_STATE,
    ON_NEW_DATA,
    () => {
      message.success('提交成功');
      goList();
    },
    err => handleError(err),
  );
  if (state.loading) {
    return <Loading />;
  }
  const goList = () => {
    history.push({ pathname: '/applys' });
  };

  return (
    <div>
      <div className={styles.selfIntroduction}>
        <div className={styles.titleIntroduction}>参赛宣言</div>
        <div className={styles.padBetweenLine}></div>
        <div>
          <p>{props.declaration}</p>
        </div>
      </div>
      {
        props.source === 'applys' ? <div className={styles.auditBtnStyle}>
          <Button
            type="primary"
            loading={mutateState.loading}
            className={styles.passedBtnStyle}
            onClick={() => callApi({ body: { status: 1, gameAreaId } })}
          >
            审核通过
          </Button>
          <Button
            type="danger"
            loading={mutateState.loading}
            className={styles.rejectedBtnStyle}
            onClick={() => callApi({ body: { status: 2, gameAreaId } })}
          >
            审核不通过
          </Button>
        </div> : null
      }
    </div>
  );
};

export default AboutHer;
