import React from 'react';
import { listMPlayers } from '@/utils/api';
import useReactRouter from 'atv-use-react-router';
import { useTableHelper, useEnsureGameArea, useUpdateEffect } from '@/utils/hooks';

import { Table, Button } from 'antd';
import Thumbnails from '@/components/Thumbnails';

const PAGE_SIZE = 8;

function MyTable({ name, tabVal, flag, onChange }) {
  const gameAreaId = useEnsureGameArea();
  const qValue = { gameAreaId, status: tabVal };
  if (name.length !== 0) {
    qValue.name = { $like: `%${name}%` };
  }
  const q = JSON.stringify(qValue);

  const { state, page, handlePaginate } = useTableHelper(listMPlayers, () => {}, { pageSize: PAGE_SIZE, q });
  const { history } = useReactRouter();

  useUpdateEffect(() => {
    handlePaginate(1);
  }, [gameAreaId, name, tabVal, flag]); // eslint-disable-line

  const handleTableChange = pagination => {
    handlePaginate(pagination.current);
  };

  const columns = [
    {
      title: 'ID',
      width: 100,
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: '编号',
      width: 100,
      align: 'center',
      dataIndex: 'no',
      render: e => e || '-',
    },
    {
      title: '头像',
      width: 100,
      align: 'center',
      dataIndex: 'avatarUrl',
      render: v => <Thumbnails images={[v]} center />,
    },
    {
      title: '中文姓名',
      width: 100,
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '身高cm',
      width: 100,
      align: 'center',
      dataIndex: 'height',
    },
    {
      title: '体重kg',
      width: 100,
      align: 'center',
      dataIndex: 'weight',
    },
    {
      title: '三围',
      width: 100,
      align: 'center',
      render: (text, record) => <span>{record.bust}-{record.waist}-{record.hips}</span>,
    },
    {
      title: '参赛宣言',
      width: 100,
      align: 'center',
      dataIndex: 'declaration',
      render: e => e || '-',
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      render: e => (
        <>
          <Button
            type='link'
            onClick={() => history.push('/applys/' + e.id)}
          >
            查看
          </Button>
          {tabVal === 0 && (
            <Button
              type='link'
              onClick={(event) => {
                event.preventDefault();
                onChange(e.id);
              }}
            >
              修改
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={state.data.data}
      pagination={{ current: page, pageSize: PAGE_SIZE, total: state.data.count }}
      onChange={handleTableChange}
      loading={state.loading}
      columns={columns}
    />
  );
}

export default MyTable;
