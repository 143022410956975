/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import { useUpdateEffect } from '@/utils/hooks';
import { Modal, Icon } from 'antd';
import styles from './index.module.scss';

function Previews({ current, onChange }) {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [preview, setPreview] = useState('');

  useEffect(() => {
    if (current) {
      setPreview(current);
    }
  }, [current]);

  useUpdateEffect(() => {
    if (preview && !load) {
      setLoad(true);
      setError(false);
    }
  }, [preview]);

  const renderImage = () => {
    return (
      <div className={styles.imageBox}>
        {(load || error) && (
          <div className={styles.tipBox}>
            <Icon
              type={load ? 'loading' : 'area-chart'}
              className={styles.tipIcon}
            />
            <p className={styles.tipText}>{load ? '加载中···' : '加载失败'}</p>
          </div>
        )}
        <img
          alt=''
          src={preview}
          onLoad={() => setLoad(false)}
          style={{ display: load || error ? 'none' : '' }}
          onError={() => {
            setError(true);
            setLoad(false);
          }}
        />
      </div>
    );
  };

  return (
    <Modal
      width={0}
      footer={null}
      destroyOnClose
      closable={false}
      visible={!!preview}
      bodyStyle={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        padding: '0',
        left: 0,
        top: 0,
      }}
    >
      <div
        className={styles.previews}
        onClick={(e) => {
          e.stopPropagation();
          setPreview('');
          setTimeout(() => onChange(), 300);
        }}
      >
        {renderImage()}
      </div>
    </Modal>
  );
}

export default Previews;
