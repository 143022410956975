import React, { useEffect } from 'react';
import styles from './index.module.scss';

import useAppContext from '@/useAppContext';
import useReactRouter from 'atv-use-react-router';

function GameAreaMask() {
  const { gameAreaId } = useAppContext();
  const { history } = useReactRouter();
  useEffect(() => {
    if (gameAreaId) {
      history.goBack();
    }
  }, [gameAreaId]); // eslint-disable-line
  return (
    <div className={styles.gameAreaMask}>
      <div className={styles.maskText}>
        <span>请先点击左上角选择赛区</span>
      </div>
    </div>
  );
}

export default GameAreaMask;
