/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import styles from './style.module.scss';

function ImageLoad({ src, size, icon, shape, children, className }) {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (src && !load) {
      setLoad(true);
      setError(false);
    } else if (!src) {
      setError(true);
    }
  }, [src]);

  const renderLoad = () => {
    return (
      <img
        alt=""
        src={String(src)}
        onError={() => {
          setLoad(false);
          setError(true);
        }}
        className={styles.loadingImg}
        onLoad={() => setLoad(false)}
      />
    );
  };

  return (
    <>
      {(error || load) && (
        <div className={className || styles.container}>
          <Avatar size={size} icon={load ? 'loading' : icon} shape={shape} />
        </div>
      )}
      <div className={error || load ? styles.none : null}>{children}</div>
      {load && renderLoad()}
    </>
  );
}

ImageLoad.defaultProps = {
  src: '',
  className: null,
};

ImageLoad.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
};

export default ImageLoad;
