import React, { useEffect, useState } from 'react';
import { getPlayer } from '@/utils/api';
import PlayerProduce from './components/PlayerProduce';
import BasicInfo from './components/BasicInfo';
import AccurateInfo from './components/AccurateInfo';
import AboutHer from './components/AboutHer';
import Loading from '@/components/Loading';
import styles from './index.module.scss';

const PlayerFields = ({ match }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { params = {} } = match;
  const { status } = data || {};
  const urlList = ['applys', 'players', 'unpassed'];

  useEffect(() => {
    if (loading) {
      getPlayer({
        params,
      }).then(res => {
        setData(res);
        setLoading(false);
      });
    }
  }, [loading]); // eslint-disable-line

  return loading ? <Loading /> : (
    <div className={styles.container}>
      <PlayerProduce
        label="个人"
        {...data}
        source={urlList[status]}
      />
      <BasicInfo
        label="基本介绍"
        {...data}
      />
      <AccurateInfo
        label="详细介绍"
        {...data}
      />
      <AboutHer
        label="其他信息"
        {...data}
        source={urlList[status]}
      />
    </div>
  );
};

export default PlayerFields;
