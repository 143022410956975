import { useReducer } from 'react';
import createUseContext from 'constate';
import produce from 'immer';
import { saveAuth, getAuth, getGameAreaId, saveGameAreaId, clearAuth, toLogin } from './utils/app';

const initialState = {
  gameAreaId: getGameAreaId(),
  authValue: getAuth(),
};

const reducer = (state, action) => produce(state, draft => {
  switch (action.type) {
    case 'UPDATE_GAMEAREA':
      draft.gameAreaId = action.payload;
      break;
    case 'UPDATE_AUTH':
      draft.authValue = action.payload;
      break;
    default:
  }
});

const useHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { gameAreaId, authValue } = state;
  const updateGameArea = id => {
    dispatch({ type: 'UPDATE_GAMEAREA', payload: id });
    saveGameAreaId(id);
  };
  const updateAuthValue = (authValue, persist) => {
    dispatch({ type: 'UPDATE_AUTH', payload: authValue });
    if (authValue.gameAreaId) {
      dispatch({ type: 'UPDATE_GAMEAREA', payload: authValue.gameAreaId });
    }
    saveAuth(authValue, persist);
  };
  const logout = () => {
    clearAuth();
    toLogin();
  };
  return { gameAreaId, updateGameArea, updateAuthValue, logout, authValue };
};

const useAppContext = createUseContext(useHook);

export default useAppContext;
