/* eslint-disable react/no-array-index-key */
/* eslint-disable react/default-props-match-prop-types */
import React, { useState } from 'react';
import { Icon, Avatar } from 'antd';
import PropTypes from 'prop-types';
import ImageLoad from './components/ImageLoad';
import Previews from '../Previews';
import styles from './index.module.scss';

function Thumbnails({
  fix,
  border,
  center,
  size = 64,
  Thumbnail,
  images = [],
  shape = 'square',
  icon = 'area-chart',
}) {
  const [current, setCurrent] = useState(null);

  return (
    <>
      <div
        className={styles.previewList}
        style={center ? { justifyContent: 'center' } : {}}
      >
        {images.map((value, index) => (
          <div
            key={index}
            className={styles.previewItem}
          >
            {value && (
              <div
                style={{
                  width: `${size}px`,
                  height: `${size}px`,
                }}
                className={styles.toBeCenter}
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrent(value);
                }}
              >
                <Icon type="eye" className={styles.openModal} />
              </div>
            )}
            {Thumbnail({ value, size, shape, icon, border, fix })}
          </div>
        ))}
      </div>
      {current && (
        <Previews
          current={current}
          onChange={() => setCurrent('')}
        />
      )}
    </>
  );
}

Thumbnails.defaultProps = {
  Thumbnail: data => (
    <ImageLoad
      src={data.value}
      size={data.size}
      icon={data.icon}
      shape={data.shape}
    >
      <Avatar
        src={data.value}
        size={data.size}
        shape={data.shape}
        className={data.fix && styles.fit}
        style={data.border && { border: '1px dashed #d9d9d9' }}
      />
    </ImageLoad>
  ),
};

Thumbnails.propTypes = {
  images: PropTypes.array.isRequired,
};

export default Thumbnails;
