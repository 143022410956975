import React from 'react';
import { Formik } from 'formik';
import { Input } from '@jbuschke/formik-antd';
import { message, Row, Col, Button } from 'antd';
import Loading from '@/components/Loading';
import FormHeader from '@/components/FormHeader';
import AliossUploadImage from '@/components/AliossUploadImage/index';
import H5Editor from '@/components/H5Editor/index';
import { useIdOrNew, useGenericErrorHandler, useEnsureGameArea } from '@/utils/hooks';
import useAppContext from '@/useAppContext';
import FormErrorMessage from '@/components/FormErrorMessage';
import useFetch, { INITIAL_STATE, ON_NEW_DATA } from 'atv-use-fetch';
import { getGameEvent, addGameEvent, updateGameEvent } from '@/utils/api';
import styles from './index.module.scss';

const errorMessages = {
  title: '请输入标题',
  introduction: '请输入详细内容',
  images: '请上传配图',
};

const getErrorMessage = (value) => {
  return Object.keys(errorMessages).reduce((result, name) => {
    if (!value[name]) {
      result[name] = errorMessages[name];
    }
    return result;
  }, {});
};

function EventDetail() {
  useEnsureGameArea();
  const mapResData = res => res;
  const initialValues = {
    title: '',
    images: null,
    introduction: '',
  };
  const { state, history, id, isNew } = useIdOrNew(getGameEvent, mapResData, initialValues);
  const { gameAreaId } = useAppContext();
  const handleError = useGenericErrorHandler();
  const { state: mutateState, callApi } = useFetch(
    data => isNew ? addGameEvent(data) : updateGameEvent({ params: { id }, ...data }),
    INITIAL_STATE,
    ON_NEW_DATA,
    () => {
      message.success(isNew ? '添加成功' : '修改成功');
      goBack();
    },
    err => handleError(err),
  );
  if (state.loading) {
    return <Loading />;
  }
  const goBack = () => {
    history.push({ pathname: '/events' });
  };
  const formHeaderClick = (i) => {
    i === 0 && goBack();
  };
  return (
    <div>
      <FormHeader names={['大赛动态列表', '大赛动态详情']} onClick={formHeaderClick} />

      <div className={styles.formContainer}>
        <Formik
          initialValues={state.data}
          onSubmit={(values) => {
            callApi({ body: { ...values, gameAreaId, status: 1 } });
          }}
          validate={values => getErrorMessage(values) }
          render={props => (
            <div className={styles.formItems}>
              <Row className={styles.formItem}>
                <Col className={styles.formItemCol}>
                  <label className={styles.titleContent}>标题: </label>
                  <div className={styles.showContent} >
                    <Input
                      className={styles.inputStyle}
                      name="title"
                      maxLength={30}
                      placeholder="请输入大赛动态标题"
                    />
                    <FormErrorMessage name="title" />
                  </div>
                </Col>
              </Row>

              <Row className={styles.formItem}>
                <Col className={styles.formItemCol}>
                  <label className={styles.titleContent}>配图: </label>
                  <div className={styles.showContent} >
                    <div className={styles.imgContent} >
                      <div className={styles.aliImg}>
                        <AliossUploadImage
                          defaultValue={state.data.images}
                          onChange={v => props.setFieldValue('images', v)}
                        />
                      </div>
                      <p className={styles.imgWarning}>请上传一张大赛配图，建议尺寸690 * 422像素，格式png。jpg</p>
                      <FormErrorMessage name="images" />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className={styles.formItem}>
                <Col className={styles.formItemCol}>
                  <label className={styles.titleContent}>详细内容: </label>
                  <div className={styles.editorContent}>
                    <H5Editor
                      defaultValue={state.data.introduction}
                      onChange={e => props.setFieldValue('introduction', e)}
                    />
                    <FormErrorMessage name="introduction" />
                  </div>
                </Col>
              </Row>
              <Row className={styles.formItem}>
                <Button
                  type="primary"
                  block
                  className={styles.subBtnStyle}
                  loading={mutateState.loading}
                  onClick={props.handleSubmit}
                >
                  提交
                </Button>
                <Button
                  block
                  className={styles.backBtnStyle}
                  onClick={goBack}
                >
                  返回列表
                </Button>
              </Row>
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default EventDetail;
