import React from 'react';
import { useTableHelper, useEnsureGameArea, useUpdateEffect } from '@/utils/hooks';
import ActionsEditDelete from '@/components/ActionsEditDelete';
import useReactRouter from 'atv-use-react-router';
import { listGameEvent, updateGameEvent } from '@/utils/api';
import Thumbnails from '@/components/Thumbnails';
import { Table, message, Modal } from 'antd';

const { confirm } = Modal;

const PAGE_SIZE = 8;

function DeleteModal(handleDelete, record) {
  confirm({
    title: '确认删除此动态？',
    okText: '确定',
    okType: 'danger',
    cancelText: '取消',
    icon: <i></i>,
    onOk() {
      record && handleDelete(record);
      message.success('删除成功');
    },
    onCancel() {
    },
  });
}

export default function MainTable() {
  const gameAreaId = useEnsureGameArea();
  const qValue = { gameAreaId, status: 1 };
  const q = JSON.stringify(qValue);

  const callDeleteApi = record => updateGameEvent({ params: { id: record.id }, body: { gameAreaId, status: 0 } });
  const { state, page, handlePaginate, handleDelete } = useTableHelper(listGameEvent, callDeleteApi, { pageSize: PAGE_SIZE, q });
  const { history } = useReactRouter();

  useUpdateEffect(() => {
    handlePaginate(1);
  }, [gameAreaId]); // eslint-disable-line

  const handleTableChange = pagination => {
    handlePaginate(pagination.current);
  };

  const handleEdit = record => {
    history.push('/events/' + record.id);
  };

  const columns = [
    {
      title: '序号',
      width: 60,
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: '配图',
      width: 100,
      align: 'center',
      dataIndex: 'images',
      render: v => <Thumbnails images={[v]} center />,
    },
    {
      title: '标题',
      width: 200,
      align: 'center',
      dataIndex: 'title',
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      render: (text, record) =>
        <ActionsEditDelete
          onEditBtnClick={() => handleEdit(record)}
          onDeleteBtnclick={() => DeleteModal(handleDelete, record)}
        />,
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={state.data.data}
      pagination={{ current: page, pageSize: PAGE_SIZE, total: state.data.count }}
      onChange={handleTableChange}
      loading={state.loading}
      columns={columns}
    />
  );
}
