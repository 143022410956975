import React, { useEffect, useState } from 'react';
import { listGameArea, listUser, manageApplyPlayer, getPlayer, manageUpdateApplyInfo } from '@/utils/api';
import { Card, List, Input, Select, Modal, message, Radio, DatePicker, InputNumber, Spin } from 'antd';
import { formatCardTitle, formatListParam } from '@/utils/render';
import AliossUploadImage from '@/components/AliossUploadImage';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import styles from './style.module.scss';

const { Option } = Select;

const paramkey = [{
  avatarUrl: '选手照片',
  images: '生活照片',
  videoUrl: '参赛视频',
}, {
  gameAreaId: '参赛赛区',
  name: '中文姓名',
  englishName: '英文姓名',
  status: '状态',
  gender: '性别',
  country: '国籍',
  nation: '民族',
  originPlace: '籍贯',
  bornAt: '出生日期',
  idCardNo: '身份证号',
  isMarried: '婚姻状况',
  // regionId: '省市区',
  currentAddress: '详细地址',
  phone: '手机号码',
  wechat: '微信号码',
  email: '电子邮箱',
}, {
  height: '身高（CM）',
  weight: '体重（KG）',
  bust: '胸围（CM）',
  waist: '腰围（CM）',
  hips: '臀围（CM）',
  shoeSize: '鞋码（EU）',
  clothingSize: '衣码（SIZE）',
}, {
  education: '最高学历',
  school: '毕业院校',
  major: '所学专业',
  company: '工作单位',
  speciality: '才艺特长',
  prize: '曾获奖项',
  live: '直播平台',
  liveId: '直播平台ID',
}, {
  description: '个人介绍',
  // noInArea: '推荐人',
  declaration: '选手宣言',
}];
const mustKey = [
  'avatarUrl', 'gameAreaId', 'name',
  'country', 'gender', 'phone',
  'isMarried',
];

function SignUp({ id, visible, onChange }) {
  const [type, setType] = useState('id');
  const [user, setUser] = useState(null);
  const [param, setParam] = useState({});
  const [search, setSearch] = useState('');
  const [optionKey, setOptionKey] = useState({
    gameAreaId: [],
    gender: { 1: '女' },
    status: { 0: '待审', 1: "通过", 2: "拒绝" },
    clothingSize: [
      { label: 'S', value: 'S' },
      { label: 'M', value: 'M' },
      { label: 'L', value: 'L' },
      { label: 'XL', value: 'XL' },
      { label: 'XXL', value: 'XXL' },
    ],
    isMarried: {
      0: '未婚',
      1: '已婚',
    },
    education: [
      { label: '本科', value: '本科' },
      { label: '专科', value: '专科' },
      { label: '高中', value: '高中' },
      { label: '初中', value: '初中' },
      { label: '小学', value: '小学' },
    ],
    height: 'number',
    weight: 'number',
    bust: 'number',
    waist: 'number',
    hips: 'number',
    shoeSize: 'number',
  });

  useEffect(() => {
    if (id) {
      getPlayer({
        params: { id },
      }).then(res => {
        setParam(res);
      });
    }
  }, [id]);

  const handleSetKey = (field, value) => {
    setOptionKey(his => {
      const newHist = Object.assign({}, his);
      newHist[field] = value;
      return newHist;
    });
  };

  const handleSetData = (field, value) => {
    setParam(his => {
      const newHist = Object.assign({}, his);
      newHist[field] = value;
      return newHist;
    });
  };

  useEffect(() => {
    listGameArea({
      query: {
        pageSize: 100, q: JSON.stringify({ status: 1 }),
      },
    }).then(res => {
      const list = res.data.map(i => ({ label: i.name, value: i.id }));
      handleSetKey('gameAreaId', list);
    });
  }, []);

  const renderInput = (field, label) => {
    const options = optionKey[field];
    if (Array.isArray(options)) {
      return (
        <Select
          style={{ width: '330px' }}
          placeholder={`请选择${label}`}
          value={param[field] ? String(param[field]) : null}
          onChange={(val) => handleSetData(field, val)}
        >
          {options.map(i => (
            <Option key={i.value}>{i.label}</Option>
          ))}
        </Select>
      );
    } else if (typeof options === 'object') {
      return (
        <div className={styles.center}>
          <Radio.Group
            value={param[field]}
            onChange={(e) => {
              const val = e.target.value;
              handleSetData(field, val);
            }}
          >
            {Object.keys(options).map(i => (
              <Radio key={i} value={Number(i)}>{options[i]}</Radio>
            ))}
          </Radio.Group>
        </div>
      );
    } else if (field === 'bornAt') {
      return (
        <DatePicker
          locale={locale}
          showToday={false}
          format="YYYY-MM-DD"
          style={{ width: '330px' }}
          placeholder='请选择出生日期'
          value={param[field] ? moment(param[field]) : undefined}
          disabledDate={current => current && current > moment().endOf('day')}
          showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
          onChange={(e) => handleSetData(field, new Date(e))}
        />
      );
    } else if (options === 'number') {
      return (
        <InputNumber
          min={0}
          value={param[field]}
          style={{ width: '330px' }}
          placeholder={`请输入${label}`}
          onChange={(val) => handleSetData(field, val)}
        />
      );
    } else {
      return (
        <Input
          value={param[field]}
          style={{ width: '330px' }}
          placeholder={`请输入${label}`}
          onChange={(e) => {
            const val = e.target.value;
            handleSetData(field, val);
          }}
        />
      );
    }
  };

  const handleList = (field, index) => {
    const label = paramkey[index][field];
    return {
      l: label,
      v: renderInput(field, label),
      r: mustKey.includes(field),
    };
  };

  const handleGetUser = () => {
    const par = {};
    if (type === 'name') {
      par.name = { $like: `%${search}%` };
    } else {
      par[type] = search;
    }
    listUser({
      query: {
        q: JSON.stringify(par),
      },
    }).then(res => {
      const { data } = res || {};
      const [info] = data || [];
      if (info) {
        setUser(info);
        handleSetData('userId', info.id);
      } else {
        setUser(null);
        handleSetData('userId', '');
        message.warning('查询该用户信息不存在');
      };
    }).catch(() => {
      message.error('查询失败');
    });
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (search) handleGetUser();
    }, 500);
    return () => clearTimeout(handler);
  }, [search, type]); // eslint-disable-line

  const renderUser = () => {
    return (
      <Input.Group compact>
        <Select
          value={type}
          style={{ width: '85px' }}
          onChange={(val) => setType(val)}
        >
          <Option value="id">编号ID</Option>
          <Option value="phonenum">手机号</Option>
          <Option value="name">用户名</Option>
        </Select>
        <Input.Search
          style={{ width: '280px' }}
          placeholder='请输入用户信息搜索'
          onChange={(e) => setSearch(e.target.value)}
        />
      </Input.Group>
    );
  };

  const renderUserShow = () => {
    return !user ? (
      <span className={styles.warn}>未选择用户</span>
    ) : (
      <p className={styles.info}>
        <span>用户ID：{user.id}</span>
        <span>用户名：{user.name}</span>
      </p>
    );
  };

  const renderText = (field) => {
    const log = '虽然我不是最美丽的，但我是最认真的。都说：“认真的女人最美丽。”我希望我会是最耀眼的那颗！';
    const place = field === 'description' ? '介绍一下自己吧！！！' : log;
    return (
      <Input.TextArea
        rows={6}
        placeholder={place}
        value={param[field]}
        style={{ width: '330px', resize: 'none' }}
        onChange={(e) => {
          const val = e.target.value;
          handleSetData(field, val);
        }}
      />
    );
  };

  const renderUpload = (field, index = 0) => {
    const urlList = param[field] ? param[field].split(',') : [];
    let options = {};
    if (field === 'videoUrl') {
      options = {
        tips: '格式：mp4 3gp m3u8',
        accept: 'video/mp4, video/3gp, video/m3u8',
        sty: { width: '296px', height: '180px' },
      };
    } else if (field === 'images') {
      options = !index ? {
        tips: '尺寸：688 * 340，格式：png jpg',
        sty: { width: '296px', height: '146px' },
      } : {
        tips: '尺寸：336 * 420',
        sty: { width: '140px', height: '172px' },
      };
    }
    return (
      <AliossUploadImage
        {...options}
        defaultValue={urlList[index]}
        onChange={v => {
          urlList[index] = v;
          handleSetData(field, urlList.join(','));
        }}
      />
    );
  };

  const renderList = () => {
    const datas = [
      {
        title: '图片视频',
        list: [
          { l: '选手照片', v: renderUpload('avatarUrl'), r: true },
          { l: '参赛视频', v: renderUpload('videoUrl') },
          {
            l: '生活照片',
            v: (
              <div className={styles.imageBox}>
                {renderUpload('images', 0)}
                {renderUpload('images', 1)}
                {renderUpload('images', 2)}
              </div>
            ),
          },
        ],
      },
      {
        title: '基本信息',
        list: Object.keys(paramkey[1]).map(i => handleList(i, 1)),
      },
      {
        title: '详细资料',
        list: Object.keys(paramkey[2]).map(i => handleList(i, 2)),
      },
      {
        title: '其它资料',
        list: Object.keys(paramkey[3]).map(i => handleList(i, 3)),
      },
      {
        title: '个人介绍',
        list: [{ v: renderText('description') }],
      },
      {
        title: '选手宣言',
        list: [{ v: renderText('declaration') }],
      },
    ];
    if (!param.id) {
      datas.splice(0, 0, {
        title: '用户信息',
        list: [
          { l: '用户搜索', v: renderUser(), r: true },
          { l: '输入信息', v: renderUserShow(), r: true },
        ],
      });
    }
    return datas.map((i, k) => (
      <Card key={k} {...formatCardTitle(i.title)}>
        <List {...formatListParam(i.list)} />
      </Card>
    ));
  };

  const handleUpdate = (body) => {
    manageUpdateApplyInfo({ body }).then(() => {
      message.success('资料修改成功');
      onChange(true);
      setParam({});
      setUser(null);
    }).catch((err) => {
      message.error(err.body.message);
    });
  };

  const handleSubmit = () => {
    const flag = mustKey.every(i => param[i] || typeof param[i] === 'number');
    if (!flag) {
      message.warning('请将必填信息填写完整');
      return;
    }
    if (!param.userId && !param.id) {
      message.warning('请选择用户信息');
      return;
    }
    const { gameAreaId, bornAt } = param;
    const body = {
      ...param,
      gameAreaId: Number(gameAreaId),
    };
    if (bornAt) {
      body.bornAt = Math.floor(new Date(bornAt).getTime() / 1000);
    }
    if (param.id) {
      handleUpdate(body);
      return;
    }
    manageApplyPlayer({ body }).then(() => {
      message.success('选手报名成功');
      onChange(true);
      setParam({});
      setUser(null);
    }).catch((err) => {
      message.error(err.body.message);
    });
  };

  return (
    <Modal
      width={780}
      okText='提交'
      cancelText='取消'
      destroyOnClose
      visible={visible}
      maskClosable={false}
      style={{ top: '50px' }}
      title={id ? '修改选手资料' : '填写报名资料'}
      bodyStyle={{
        maxHeight: '720px',
        overflowY: 'auto',
        padding: '20px 100px',
        minHeight: '300px',
      }}
      onCancel={() => {
        onChange();
        setParam({});
        setUser(null);
      }}
      onOk={() => handleSubmit()}
    >
      {id && !param.id ? (
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '100px' }}>
          <Spin spinning />
        </div>
      ) : renderList()}
    </Modal>
  );
}

export default SignUp;
