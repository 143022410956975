import React from 'react';
import { Layout } from 'antd';
import Header from './components/Header';
import Aside from './components/Aside';
import { renderRoutes } from 'react-router-config';
import { basicRoutes } from '@/routes';

import styles from './index.module.scss';

export default function Basic({ route }) {
  return (
    <Layout className={styles.layout}>
      <Header />
      <Layout className={styles.layoutMain}>
        <Aside routes={basicRoutes.routes} />
        <Layout.Content className={styles.layoutContent}>
          {renderRoutes(route.routes)}
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
