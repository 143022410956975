import React, { useState } from 'react';
import MainTable from './components/MainTable';
import { Input } from '@jbuschke/formik-antd';
import { Col, Button, Tabs } from 'antd';
import { Formik } from 'formik';
import SignUp from './components/SignUp';
import styles from './index.module.scss';

function ApplyList() {
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [flag, setFlag] = useState(false);
  const [tabVal, setTabVal] = useState(0);
  const [visible, setVisible] = useState(false);
  return (
    <div className={styles.page}>
      <Formik
        initialValues={{ name: '' }}
        onSubmit={(values) => {
          setName(values.name);
        }}
        render={props => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Col>
              <Input
                name="name"
                className={styles.inputStyle}
                placeholder="请输入选手中文姓名"
              />
              <Button
                type="primary"
                block
                className={styles.subBtnStyle}
                onClick={props.handleSubmit}
              >
                查询
              </Button>
            </Col>
            <Button
              type="primary"
              onClick={() => setVisible(true)}
            >
              选手报名
            </Button>
          </div>
        )}
      />
      <div>
        <Tabs
          tabBarStyle={{ borderBottom: 0, marginTop: 10 }}
          defaultActiveKey="0"
          onChange={(key) => {
            setTabVal(Number(key));
          }}>
          <Tabs.TabPane tab="待审核" key="0">
          </Tabs.TabPane>
          <Tabs.TabPane tab="已通过" key="1">
          </Tabs.TabPane>
          <Tabs.TabPane tab="未通过" key="2">
          </Tabs.TabPane>
        </Tabs>
      </div>
      <div className={styles.mainTable}>
        <MainTable
          name={name || ''}
          tabVal={tabVal}
          flag={flag}
          onChange={(res) => {
            setId(res);
            setVisible(true);
          }}
        />
      </div>
      <SignUp
        id={id}
        visible={visible}
        onChange={(res) => {
          setId(null);
          setVisible(false);
          if (res) setFlag(e => !e);
        }}
      />
    </div>
  );
}
export default ApplyList;
