import React, { useEffect } from 'react';
import { useTableHelper, useEnsureGameArea, useUpdateEffect } from '@/utils/hooks';
import ActionsEditDelete from '@/components/ActionsEditDelete';
import useReactRouter from 'atv-use-react-router';
import { listLive, updateLive } from '@/utils/api';
import Thumbnails from '@/components/Thumbnails';
import LinkInput from '@/components/LinkInput';
import { message, Table, Modal } from 'antd';
import dayjs from '@/utils/shimDayjs';
import styles from './MainTable.module.scss';

function getLiveStatus(openAt, endAt, status) {
  const startDistance = new Date(openAt).getTime() - new Date().getTime();
  const endDistance = new Date().getTime() - new Date(endAt).getTime();
  let liveStatus = '直播中';
  if (startDistance > 0 && status) {
    liveStatus = '未直播';
  } else if (endDistance > 0 || !status) {
    liveStatus = '直播结束';
  }
  return liveStatus;
}

function formatData(data) {
  return data.reduce((result, { introductionJSON, liveUrl, status, id, openAt, endAt }) => {
    let description;
    let organization;
    let title;
    let image;
    try {
      const result = JSON.parse(introductionJSON);
      description = result.description;
      organization = result.organization;
      title = result.title;
      image = result.image;
    } catch (e) {
      description = '';
      organization = '';
      title = '';
      image = '';
    }
    result.push({
      description,
      organization,
      title,
      liveUrl,
      status,
      id,
      image,
      liveStatus: getLiveStatus(openAt, endAt, status),
      times: [dayjs(openAt).format('YYYY-MM-DD  HH:mm'), dayjs(endAt).format('YYYY-MM-DD  HH:mm')],
    });
    return result;
  }, []);
}

const PAGE_SIZE = 8;
const { confirm } = Modal;

function DeleteModal(handleDelete, record) {
  confirm({
    title: '确认删除此直播？',
    okText: '确定',
    okType: 'danger',
    cancelText: '取消',
    icon: <i></i>,
    onOk() {
      record && handleDelete(record);
      message.success('删除成功');
    },
    onCancel() {},
  });
}

export default function MainTable() {
  const gameAreaId = useEnsureGameArea();
  const { history } = useReactRouter();
  const callDeleteApi = record =>
    updateLive({ params: { id: record.id }, body: { status: 0 } });
  const q = JSON.stringify({ gameAreaId });
  const { state, page, handlePaginate, handleDelete } = useTableHelper(
    listLive,
    callDeleteApi,
    {
      pageSize: PAGE_SIZE,
      q,
    }
  );

  useUpdateEffect(() => {
    handlePaginate(1);
  }, [gameAreaId]); // eslint-disable-line

  useEffect(() => {
    return () => Modal.destroyAll();
  }, []);

  const handleTableChange = pagination => {
    handlePaginate(pagination.current);
  };

  const handleEdit = record => {
    history.push('/lives/' + record.id);
  };

  const columns = [
    {
      title: '序号',
      width: 60,
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: '标题',
      width: 100,
      align: 'center',
      dataIndex: 'title',
    },
    {
      title: '直播间图片',
      width: 100,
      align: 'center',
      dataIndex: 'image',
      render: v => <Thumbnails images={[v]} center />,
    },
    {
      title: '主办方',
      width: 100,
      align: 'center',
      dataIndex: 'organization',
    },
    {
      title: '直播地址',
      width: 100,
      align: 'center',
      dataIndex: 'liveUrl',
      render: v => <LinkInput url={v} />,
    },
    {
      title: '时间',
      width: 100,
      align: 'center',
      dataIndex: 'times',
      render: times => (
        <div>
          <div className={styles.times}>{times[0]}</div>
          <div className={styles.times}>~{times[1]}</div>
        </div>
      ),
    },
    {
      title: '状态',
      width: 100,
      align: 'center',
      dataIndex: 'liveStatus',
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      render: (text, record) => (
        <ActionsEditDelete
          onEditBtnClick={() => handleEdit(record)}
          onDeleteBtnclick={() => DeleteModal(handleDelete, record)}
        />
      ),
    },
  ];

  return (
    <Table
      rowKey="id"
      dataSource={formatData(state.data.data)}
      pagination={{
        current: page,
        pageSize: PAGE_SIZE,
        total: state.data.count,
      }}
      onChange={handleTableChange}
      loading={state.loading}
      columns={columns}
    />
  );
}
