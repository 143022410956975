import React from 'react';
import { Formik } from 'formik';
import { Input } from '@jbuschke/formik-antd';
import { login } from '@/utils/api';
import { Row, Col, Icon, Button } from 'antd';
import useAppContext from '@/useAppContext';
import styles from './index.module.scss';
import useReactRouter from 'atv-use-react-router';
import { useGenericErrorHandler } from '@/utils/hooks';
import useFetch, { INITIAL_STATE, ON_NEW_DATA } from 'atv-use-fetch';
import ErrorMessage from '@/components/FormErrorMessage';

export default function Login() {
  const { history } = useReactRouter();
  const handleError = useGenericErrorHandler();
  const { updateAuthValue } = useAppContext();
  const { state, callApi } = useFetch(
    login,
    INITIAL_STATE,
    ON_NEW_DATA,
    data => {
      updateAuthValue(data, true);
      history.push('/');
    },
    error => {
      handleError(error);
    }
  );
  return (
    <div className={styles.formContainer}>
      <h4 className={styles.formTitle}>登 录</h4>
      <Formik
        initialValues={{ name: '', password: '' }}
        onSubmit={values => {
          const { name, password } = values;
          callApi({ body: { name, password } });
        }}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = '必填';
          }
          if (!values.password) {
            errors.password = '必填';
          }
          return errors;
        }}
        render={props => (
          <div className={styles.formItems}>
            <Row className={styles.formItem}>
              <Col className={styles.formItemCol}>
                <Input
                  name="name"
                  size="large"
                  maxLength={20}
                  placeholder="用户名"
                  prefix={<Icon type="user" size="small" />}
                />
              </Col>
              <Col>
                <ErrorMessage name="name" />
              </Col>
            </Row>

            <Row className={styles.formItem}>
              <Col className={styles.formItemCol}>
                <Input
                  name="password"
                  size="large"
                  type="password"
                  placeholder="密码"
                  prefix={<Icon type="lock" size="small" />}
                />
              </Col>
              <Col>
                <ErrorMessage name="password" />
              </Col>
            </Row>

            <Row className={styles.formItem}>
              <Button
                type="primary"
                block
                loading={state.loading}
                onClick={props.handleSubmit}
                className="submitBtn"
              >
                登 录
              </Button>
            </Row>
          </div>
        )}
      />
    </div >
  );
}
