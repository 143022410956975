import React, { Fragment } from 'react';
import styles from './ScheduleList.module.scss';
import dayjs from 'dayjs';
import ActionsEditDelete from '@/components/ActionsEditDelete';
import useFetch, { INITIAL_STATE } from 'atv-use-fetch';
import { message } from 'antd';
import { updateSchedule } from '@/utils/api';
import { useGenericErrorHandler } from '@/utils/hooks';

function Schedule({ list, handleEdit, formikProps, delecteSchedule, isNew }) {
  const handleError = useGenericErrorHandler();
  const { state: mutateState, callApi } = useFetch(
    id => {
      return updateSchedule({ params: { id }, body: { status: 0 } });
    },
    INITIAL_STATE,
    () => {
      const {
        setFieldValue,
        values: { Schedule, deletdId },
      } = formikProps;
      setFieldValue('Schedule', Schedule.filter(({ id }) => id !== deletdId));
    },
    () => {
      message.success('删除成功');
    },
    err => handleError(err)
  );
  return (
    <Fragment>
      {list.map(({ beginAt, endAt, name, id }) => (
        <div className={styles.createItem} key={id}>
          <div className={styles.messageItem}>
            <div className={styles.times}>
              <div className={styles.startTime}>
                {dayjs(beginAt).format('YYYY-MM-DD  HH:mm')}
              </div>
              至
              <div className={styles.endTime}>
                {dayjs(endAt).format('YYYY-MM-DD  HH:mm')}
              </div>
            </div>
            <div className={styles.name}>{name}</div>
          </div>
          <div className={styles.actionsList}>
            <ActionsEditDelete
              onEditBtnClick={() => {
                handleEdit({ beginAt, endAt, name, formikProps, id });
              }}
              onDeleteBtnclick={() => {
                if (isNew) {
                  delecteSchedule({ formikProps, id, list });
                } else {
                  const { setFieldValue } = formikProps;
                  setFieldValue('deletdId', id);
                  callApi(id, mutateState);
                }
              }}
            />
          </div>
        </div>
      ))}
    </Fragment>
  );
}
export default Schedule;
