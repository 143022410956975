import React from 'react';
import { message, Button, Row, Col } from 'antd';
import { Input } from '@jbuschke/formik-antd';
import { Formik } from 'formik';
import { createManageAccount, getManageAccount, updateManageAccount } from '@/utils/api';
import Loading from '@/components/Loading';
import AliossUploadImage from '@/components/AliossUploadImage';
import FormHeader from '@/components/FormHeader';
import styles from './index.module.scss';
import { useIdOrNew, useGenericErrorHandler, useEnsureGameArea } from '@/utils/hooks';
import useFetch, { INITIAL_STATE, ON_NEW_DATA } from 'atv-use-fetch';
import FormErrorMessage from '@/components/FormErrorMessage';

const errorMessages = {
  password: '请输入密码',
  name: '请输入名称',
  avatarUrl: '请上传配图',
};

const getErrorMessage = (value, isNew) => {
  return Object.keys(errorMessages).reduce((result, name) => {
    if (isNew && !value[name]) {
      result[name] = errorMessages[name];
    }
    return result;
  }, {});
};

function ManagerDetail() {
  const gameAreaId = useEnsureGameArea();
  const mapResData = res => res;
  const initialValues = { name: '', password: '', avatarUrl: '' };
  const { state, history, id, isNew } = useIdOrNew(getManageAccount, mapResData, initialValues);
  const handleError = useGenericErrorHandler();
  const { state: mutateState, callApi } = useFetch(
    data => isNew ? createManageAccount(data) : updateManageAccount({ params: { id }, ...data }),
    INITIAL_STATE,
    ON_NEW_DATA,
    () => {
      message.success(isNew ? '添加成功' : '修改成功');
      goBack();
    },
    err => handleError(err),
  );
  if (state.loading) {
    return <Loading />;
  }
  const goBack = () => {
    history.push('/managers');
  };
  const routerState = history.location.state;
  const formHeaderClick = (i) => {
    if (routerState === 'fromHeader') {
      return null;
    }
    i === 0 && history.push('/managers');
  };
  return (
    <div>
      <FormHeader names={['子账户', '子账户详情']} onClick={formHeaderClick} />
      <div className={styles.formContainer}>
        <Formik
          initialValues={state.data}
          onSubmit={(values, actions) => {
            callApi({ body: { ...values, gameAreaId } });
          }}
          validate={(values) => getErrorMessage(values, isNew)}
          render={props => (
            <div className={styles.formItems}>
              <Row className={styles.formItem}>
                <Col className={styles.formItemCol}>
                  <label className={styles.titleContent}>名称: </label>
                  <div className={styles.showContent} >
                    <Input
                      className={styles.inputStyle}
                      name="name"
                      disabled={!isNew}
                      size="large"
                      maxLength={20}
                      placeholder="请输入名称"
                    />
                    <FormErrorMessage name="name" />
                  </div>
                </Col>
              </Row>
              <Row className={styles.formItem}>
                <Col className={styles.formItemCol}>
                  <label className={styles.titleContent}>密码: </label>
                  <div className={styles.showContent} >
                    <Input
                      className={styles.inputStyle}
                      name="password"
                      size="large"
                      type=""
                      placeholder="请输入密码"
                    />
                    <FormErrorMessage name="password" />
                  </div>
                </Col>
              </Row>
              <Row className={styles.formItem}>
                <Col className={styles.formItemCol}>
                  <label className={styles.titleContentImg}>配图: </label>
                  <div className={styles.uploadImg}>
                    <div className={styles.showContent} >
                      <div className={styles.imgContent} >
                        <AliossUploadImage
                          defaultValue={state.data.avatarUrl}
                          onChange={v => props.setFieldValue('avatarUrl', v)}
                        />
                        <p className={styles.formatTips}>限上传一张配图，建议尺寸为240X240像素</p>
                        <FormErrorMessage name="avatarUrl" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className={styles.formItem}>
                <Button
                  type="primary"
                  block
                  className={styles.subBtnStyle}
                  loading={mutateState.loading}
                  onClick={props.handleSubmit}
                >
                  提交
                </Button>
                <Button
                  block
                  className={styles.backBtnStyle}
                  onClick={goBack}
                >
                  返回列表
                </Button>
              </Row>
            </div>
          )}
        />
      </div>

    </div>
  );
}

export default ManagerDetail;
