import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

export default function TableHeader({ name, hide, onAddBtnClick }) {
  return (
    <div className={styles.tableHeader}>
      {!hide && <div>{name}</div>}
      <Button type="primary" icon="plus" onClick={onAddBtnClick}>
        添加{name}
      </Button>
    </div>
  );
}

TableHeader.propTypes = {
  name: PropTypes.string.isRequired,
  onAddBtnClick: PropTypes.func,
};
