import React from 'react';
import ErrorMessage from '@/components/FormErrorMessage';
import { Button, Col, DatePicker, message, Modal, Row } from 'antd';
import styles from './CreateSchedule.module.scss';
import { Input } from '@jbuschke/formik-antd';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { useGenericErrorHandler } from '@/utils/hooks';
import useFetch, { INITIAL_STATE } from 'atv-use-fetch';
import { updateSchedule, addSchedule } from '@/utils/api';

const { RangePicker } = DatePicker;
const changeTime = (times, props) => {
  const { setFieldValue } = props;
  if (times.length) {
    setFieldValue('beginAt', times[0].$d);
    setFieldValue('endAt', times[1].$d);
  } else {
    setFieldValue('times', null);
    setFieldValue('beginAt', null);
    setFieldValue('endAt', null);
  }
};
function CreateSchedule({
  handleOk,
  handleCancel,
  CreateType,
  addNewVisible,
  formikProps,
  gameAreaId,
  isNew,
}) {
  const handleError = useGenericErrorHandler();
  const { state: mutateState, callApi } = useFetch(
    data =>
      CreateType
        ? addSchedule(data)
        : updateSchedule({
          params: { id: formikProps.values.waitQueue.id },
          ...data,
        }),
    INITIAL_STATE,
    () => {
      if (!CreateType) {
        const {
          values: { Schedule, waitQueue },
          setFieldValue,
        } = formikProps;
        setFieldValue(
          'Schedule',
          Schedule.map(value => (value.id === waitQueue.id ? waitQueue : value))
        );
      }
    },
    value => {
      message.success(CreateType ? '添加成功' : '修改成功');
      if (CreateType) {
        const {
          values: { Schedule },
          setFieldValue,
        } = formikProps;
        setFieldValue('Schedule', [...Schedule, value]);
      }
      return handleOk();
    },
    err => handleError(err)
  );
  return (
    <Modal
      title={CreateType ? '添加日程' : '修改日程'}
      cancelText="取消"
      okText="确定"
      visible={addNewVisible}
      footer={null}
      className={styles.modalContent}
      onCancel={handleCancel}
    >
      <Formik
        enableReinitialize
        initialValues={formikProps.values.waitQueue}
        validate={values => {
          const errors = {};
          if (!values.name) {
            errors.name = '请输入标题名称，如：半决赛';
          }
          if (!values.beginAt) {
            errors.times = '时间还未选择';
          }
          if (!values.endAt) {
            errors.times = '时间还未选择';
          }
          return errors;
        }}
        onSubmit={({ beginAt, endAt, name }) => {
          const {
            values: { waitQueue, Schedule },
            setFieldValue,
          } = formikProps;
          const result = {
            ...waitQueue,
            ...{
              beginAt: new Date(beginAt).toJSON(),
              endAt: new Date(endAt).toJSON(),
              name,
              status: 1,
              isVotable: 1,
              gameAreaId,
            },
          };
          setFieldValue('waitQueue', result);
          if (isNew) {
            message.success(CreateType ? '添加成功' : '修改成功');
            if (CreateType) {
              setFieldValue('Schedule', [
                ...Schedule,
                {
                  ...waitQueue,
                  ...{
                    beginAt: new Date(beginAt).toJSON(),
                    endAt: new Date(endAt).toJSON(),
                    name,
                    status: 1,
                    isVotable: 1,
                    id: Schedule[Schedule.length - 1]
                      ? Schedule[Schedule.length - 1].id + 1
                      : 0,
                  },
                },
              ]);
            } else {
              setFieldValue(
                'Schedule',
                Schedule.map(value =>
                  value.id === waitQueue.id ? {
                    ...waitQueue,
                    ...{
                      beginAt: new Date(beginAt).toJSON(),
                      endAt: new Date(endAt).toJSON(),
                      name,
                      status: 1,
                      isVotable: 1,
                    },
                  } : value
                )
              );
            }
            return handleOk();
          }
          callApi({
            body: result,
          });
        }}
        render={props => (
          <Row className={styles.formItem}>
            <div className={styles.modalInterval}>
              <Col
                style={{
                  paddingBottom: '21px',
                }}
              >
                <div className={styles.modalTitle}>标题: </div>
                <div className={styles.showContent}>
                  <Input
                    className={styles.modalInput}
                    name="name"
                    placeholder="请输入标题名称，如：半决赛"
                  />
                  <ErrorMessage name="name" />
                </div>
              </Col>
              <Col
                style={{
                  paddingBottom: '21px',
                }}
              >
                <div className={styles.modalTitle}>时间: </div>
                <div>
                  <RangePicker
                    value={
                      props.values.beginAt
                        ? [
                          dayjs(props.values.beginAt),
                          dayjs(props.values.endAt),
                        ]
                        : null
                    }
                    size="large"
                    name="times"
                    placeholder={['开始日期', '结束日期']}
                    separator="至"
                    onChange={value => changeTime(value, props)}
                    className={styles.modalInput}
                    showTime={true}
                  />
                </div>
                <ErrorMessage name="times" />
              </Col>
            </div>
            <Col className={styles.footerList}>
              <Button onClick={handleCancel}>取消</Button>
              <Button
                onClick={props.handleSubmit}
                className={styles.send}
                loading={mutateState.loading}
              >
                确定
              </Button>
            </Col>
          </Row>
        )}
      />
    </Modal>
  );
}
export default CreateSchedule;
