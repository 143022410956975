import React from 'react';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

export default function OrderLevel(props) {
  return (
    <div className={styles.btns}>
      {!props.record.first && <Icon className={styles.editBtn} style={props.record.last && { marginRight: 0 }} type="arrow-up" onClick={props.onUp} />}
      {!props.record.last && <Icon className={styles.deleteBtn} type="arrow-down" onClick={props.onDown} />}
    </div>
  );
}

OrderLevel.propTypes = {
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  record: PropTypes.object,
};
