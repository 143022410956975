import React from 'react';
import { Button, DatePicker, message, Switch } from 'antd';
import useFetch, { INITIAL_STATE, ON_NEW_DATA } from 'atv-use-fetch';
import { Input } from '@jbuschke/formik-antd';
import { Formik } from 'formik';
import FormHeader from '@/components/FormHeader';
import ErrorMessage from '@/components/FormErrorMessage';
import Loading from '@/components/Loading';
import { addLive, getLive, updateLive } from '@/utils/api';
import { useIdOrNew, useGenericErrorHandler, useEnsureGameArea } from '@/utils/hooks';
import formatTime from '@/utils/shimDayjs';
import styles from './index.module.scss';
import AliossUploadImage from '@/components/AliossUploadImage';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

function LiveDetail() {
  const gameAreaId = useEnsureGameArea();
  const mapResData = res => {
    const { liveUrl, openAt, endAt, introductionJSON, status } = res;
    let description;
    let organization;
    let title;
    let image;
    try {
      const result = JSON.parse(introductionJSON);
      description = result.description;
      organization = result.organization;
      title = result.title;
      image = result.image;
    } catch (e) {
      description = '';
      organization = '';
      title = '';
      image = '';
    }
    return { liveUrl, times: { openAt, endAt }, title, description, image, organization, status };
  };
  const changeAvatarUrl = (url, props) => {
    const { setFieldValue } = props;
    setFieldValue('image', url);
  };
  const initialValues = { liveUrl: '', title: '', description: '', organization: '', image: '', times: null, status: 0 };
  const { state, history, id, isNew } = useIdOrNew(getLive, mapResData, initialValues);
  const handleError = useGenericErrorHandler();
  const { state: mutateState, callApi } = useFetch(
    data => isNew ? addLive(data) : updateLive({ params: { id }, ...data }),
    INITIAL_STATE,
    ON_NEW_DATA,
    () => {
      message.success(isNew ? '添加成功' : '修改成功');
      goList();
    },
    err => handleError(err),
  );
  if (state.loading) {
    return <Loading />;
  }
  const changeTime = (times, props) => {
    const { setFieldValue } = props;
    if (times.length) {
      setFieldValue('times', {
        openAt: times[0].$d,
        endAt: times[1].$d,
      });
    } else setFieldValue('times', null);
  };
  const onSumbit = async values => {
    const {
      liveUrl,
      times: { openAt, endAt },
      description,
      organization,
      title,
      status,
      image,
    } = values;
    const requestObject = {
      liveUrl,
      openAt,
      endAt,
      gameAreaId,
      status,
      introductionJSON: JSON.stringify({
        description,
        organization,
        title,
        image,
      }),
    };
    callApi({ body: requestObject });
  };
  const goList = () => {
    history.push({ pathname: '/lives' });
  };
  const formHeaderClick = (i) => {
    i === 0 && goList();
  };

  const switchStatus = (value, props) => {
    const { setFieldValue } = props;
    setFieldValue('status', value ? 1 : 0);
  };
  return (
    <div className={styles.container}>
      <FormHeader names={['直播', '直播详情']} onClick={formHeaderClick} />
      <div className={styles.fullScreen}>
        <Formik
          initialValues={state.data}
          onSubmit={values => onSumbit(values)}
          validate={values => {
            const errors = {};
            if (!values.liveUrl) {
              errors.liveUrl = '请输入直播地址';
            }
            if (!values.description) {
              errors.description = '请输入直播简介';
            }
            if (!values.organization) {
              errors.organization = '请输入主办方名称';
            }
            if (!values.title) {
              errors.title = '请输入直播标题';
            }
            if (!values.image) {
              errors.image = '请上传直播间图片';
            }
            if (!values.times) {
              errors.times = '请选择直播时间';
            }
            return errors;
          }}
          render={props => (
            <div className={styles.globalInterval}>
              <div>
                <div className={styles.toBeCenter}>
                  <div className={styles.title}>直播标题：</div>
                  <div>
                    <Input name="title" size="default" placeholder="请输入直播标题" />
                    <ErrorMessage name="title" />
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.description}>
                  <div className={styles.toBeHorizontal}>
                    <div className={styles.title}>直播间图片：</div>
                    <div className={styles.uploadImg}>
                      <div className={styles.imgContent}>
                        <AliossUploadImage
                          defaultValue={state.data.image}
                          onChange={(value) => {
                            changeAvatarUrl(value, props);
                          }}
                        />
                      </div>
                      <p className={styles.imgFormat}>请上传一张直播图片，建议尺寸750 * 422像素，格式png、jpg</p>
                      <ErrorMessage name="image" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.description}>
                  <div className={styles.toBeHorizontal}>
                    <div className={styles.title}>直播简介：</div>
                    <div>
                      <TextArea rows={2} name="description" size="default" placeholder="请输入直播简介" />
                      <ErrorMessage name="description" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.toBeCenter}>
                  <div className={styles.title}>主办方：</div>
                  <div>
                    <Input name="organization" size="default" placeholder="请输入主办方名称" />
                    <ErrorMessage name="organization" />
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.toBeCenter}>
                  <div className={styles.title}>时间：</div>
                  <div>
                    <RangePicker
                      placeholder={['开始时间', ' 结束时间']}
                      onChange={value => changeTime(value, props)}
                      defaultValue={
                        state.data.times
                          ? [formatTime(state.data.times.openAt), formatTime(state.data.times.endAt)]
                          : null
                      }
                      showTime={true}
                    />
                    <ErrorMessage name="times" />
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.toBeCenter}>
                  <div className={styles.title}>直播地址：</div>
                  <div>
                    <Input name="liveUrl" size="default" placeholder="请输入直播地址" />
                    <ErrorMessage name="liveUrl" />
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.toBeCenter}>
                  <div className={styles.title}>状态：</div>
                  <div>
                    <div className={styles.toBeHorizontal}>
                      <Switch
                        checkedChildren="上架"
                        unCheckedChildren="下架"
                        defaultChecked={!!state.data.status}
                        onChange={(value) => switchStatus(value, props)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <Button
                  onClick={props.handleSubmit}
                  loading={mutateState.loading}
                  className={styles.submitBtn}
                >
                提交
                </Button>
                <Button
                  className={styles.backBtn}
                  onClick={goList}
                >
                返回列表
                </Button>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default LiveDetail;
