import makeRequest from '@/utils/makeRequest';

export const login = makeRequest('post', '/m/login');
export const createManageAccount = makeRequest('post', '/m/manage');
export const listManageAccount = makeRequest('get', '/m/manage');
export const getManageAccount = makeRequest('get', '/m/manage/:id');
export const deleteManageAccount = makeRequest('delete', '/m/manage/:id');
export const updateManageAccount = makeRequest('put', '/m/manage/:id');
export const getManageUploadToken = makeRequest('get', '/m/uploadToken');
export const listMPlayers = makeRequest('get', '/m/players');
export const getPlayer = makeRequest('get', '/m/players/:id');
export const auditPlayer = makeRequest('put', '/m/players/audit/:id');
export const bindMentor = makeRequest('put', '/m/players/mentor/:id');
export const listPlayerLevel = makeRequest('get', '/m/playerLevels');
export const listGameArea = makeRequest('get', '/m/gameAreas');
export const getGameArea = makeRequest('get', '/m/gameAreas/:id');
export const addGameArea = makeRequest('post', '/m/gameAreas');
export const updateGameArea = makeRequest('put', '/m/gameAreas/:id');
export const listSchedule = makeRequest('get', '/m/schedules');
export const getSchedule = makeRequest('get', '/m/schedules/:id');
export const addSchedule = makeRequest('post', '/m/schedules');
export const updateSchedule = makeRequest('put', '/m/schedules/:id');
export const listSponser = makeRequest('get', '/m/sponsers');
export const getSponser = makeRequest('get', '/m/sponsers/:id');
export const addSponser = makeRequest('post', '/m/sponsers');
export const updateSponser = makeRequest('put', '/m/sponsers/:id');
export const changeSponserLevel = makeRequest('put', '/m/sponsersLevel/change');
export const listBanner = makeRequest('get', '/m/banners');
export const getBanner = makeRequest('get', '/m/banners/:id');
export const addBanner = makeRequest('post', '/m/banners');
export const updateBanner = makeRequest('put', '/m/banners/:id');
export const listGameEvent = makeRequest('get', '/m/events');
export const getGameEvent = makeRequest('get', '/m/events/:id');
export const addGameEvent = makeRequest('post', '/m/events');
export const updateGameEvent = makeRequest('put', '/m/events/:id');
export const getSysconfig = makeRequest('get', '/m/sysconfig');
export const updateSysconfig = makeRequest('put', '/m/sysconfigs');
export const listGiftKind = makeRequest('get', '/m/giftKinds');
export const listGift = makeRequest('get', '/m/gifts');
export const listUser = makeRequest('get', '/m/users');
export const listAudience = makeRequest('get', '/m/audiences');
export const listMentor = makeRequest('get', '/m/mentors');
export const getMentor = makeRequest('get', '/m/mentors/:id');
export const addMentor = makeRequest('post', '/m/mentors');
export const updateMentor = makeRequest('put', '/m/mentors/:id');
export const listJudge = makeRequest('get', '/m/judges');
export const getJudge = makeRequest('get', '/m/judges/:id');
export const addJudge = makeRequest('post', '/m/judges');
export const updateJudge = makeRequest('put', '/m/judges/:id');
export const updateJudgeSort = makeRequest('put', '/m/judgeNo');
export const listAudienceLevel = makeRequest('get', '/m/audienceLevels');
export const listLive = makeRequest('get', '/m/lives');
export const getLive = makeRequest('get', '/m/lives/:id');
export const addLive = makeRequest('post', '/m/lives');
export const updateLive = makeRequest('put', '/m/lives/:id');
export const manageApplyPlayer = makeRequest('post', '/m/players/apply');
export const manageUpdateApplyInfo = makeRequest('post', '/m/players/updateApply');
export const manageCreateUser = makeRequest('post', '/m/user/create');
export const manageUpdateUser = makeRequest('put', '/m/user/update');
export const listGiftStats = makeRequest('get', '/m/gift/stats');
export const listGiftcharge = makeRequest('get', '/m/giftcharge');
