import AliossUploadImage from '@/components/AliossUploadImage';
import { manageCreateUser, manageUpdateUser } from '@/utils/api';
import { Input, message, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from '../index.module.scss';

function AddUser({ datas = {}, visible, onChange }) {
  const [param, setParam] = useState({});

  useEffect(() => {
    setParam(datas);
  }, [datas]);

  const handleSubmit = () => {
    if (!param.phonenum || !param.name || !param.avatarUrl) {
      message.warning('请将数据添加完整');
      return;
    }
    if (datas.id) {
      manageUpdateUser({
        body: {
          id: datas.id,
          ...param,
        },
      }).then(() => {
        message.success('修改成功');
        onChange(true);
      }).catch((err) => {
        message.error(err.body.message);
      });
    } else {
      manageCreateUser({
        body: param,
      }).then(() => {
        message.success('添加成功');
        onChange(true);
      }).catch((err) => {
        message.error(err.body.message);
      });
    }
  };

  return (
    <Modal
      okText='提交'
      cancelText='取消'
      title={datas.id ? '修改用户' : '添加用户'}
      visible={visible}
      onOk={handleSubmit}
      onCancel={() => onChange()}
    >
      <Row className={styles.col}>
        <span className={styles.colSpan}>用户手机号：</span>
        <Input
          style={{ width: '280px' }}
          placeholder='请输入用户手机号'
          value={param.phonenum}
          onChange={(e) => {
            const phonenum = e.target.value;
            setParam(his => ({ ...his, phonenum }));
          }}
        />
      </Row>
      <Row className={styles.col}>
        <span className={styles.colSpan}>用户名称：</span>
        <Input
          style={{ width: '280px' }}
          placeholder='请输入用户名称'
          value={param.name}
          onChange={(e) => {
            const name = e.target.value;
            setParam(his => ({ ...his, name }));
          }}
        />
      </Row>
      <Row className={styles.col}>
        <span className={styles.colSpan}>用户头像：</span>
        <AliossUploadImage
          defaultValue={param.avatarUrl}
          onChange={(avatarUrl) => setParam(his => ({ ...his, avatarUrl }))}
        />
      </Row>
    </Modal>
  );
}

export default AddUser;
